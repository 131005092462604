import React, { Component} from 'react';
import axios from 'axios';
import { NavLink, withRouter } from 'react-router-dom';
import { Container, Col, Row, Card } from 'react-bootstrap';
import Logo from '../../assets/logo.svg'
import config from '../../config'

const TP_API_URL = config.TP_API_URL;


class OBDiscountPP extends Component {
    state = {
        loading:false,
        error_code:0,
        error_msg:"",
        shop:{},
        discount_value_pp: 15,
        post_purchase: 0,
        rangeLeft:22.2222,
        lp:4.66667
    };

    Verify_shop = () => {
        this.setState({
            error_code:0,
            error_msg:"",
            loading:true
        });

        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");

        const ax_headers = {
            params:{
                shop_id : tp_shop
            },
            headers: {
                token: tp_access_token
            }
        }

        axios.get(TP_API_URL+"client/shop/view", ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState(
                    {
                        loading:false,
                        shop : response.data
                    }
                );

                if(response.data.post_purchase>0) {
                    this.calMargin(response.data.discount_value_pp);
                    this.setState(
                        {
                            discount_value_pp : response.data.discount_value_pp,
                            post_purchase: response.data.post_purchase
                        }
                    );
                }
            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    calMargin = (target) => {
        const val = target;
        const min = 5;
        const max = 50;
        const rangeLeft = Number(((val - min) * 100) / (max - min));
        const lp = 8 - rangeLeft*0.15;
        this.setState({
            rangeLeft: rangeLeft,
            lp : lp
        });
    }

    handleChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if(name==='post_purchase') {
            if(value) {
                value=1;
            } else {
                value=0;
            }
        }
        this.setState({
            [name]: value
        });

        if(target.type === 'range') {
            const val = target.value;
            const min = target.min ? target.min : 0;
            const max = target.max ? target.max : 100;
            const rangeLeft = Number(((val - min) * 100) / (max - min));
            const lp = 8 - rangeLeft*0.15;
            this.setState({
                rangeLeft: rangeLeft,
                lp : lp
            });
        }
    }

    Update_shop = () => {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        
        const ax_headers = {
            headers: {
                token: tp_access_token
            }
        }

        axios.post(TP_API_URL+"client/shop/update", {
            shop_id : tp_shop,
            onboarding: 2,
            discount_value_pp:this.state.discount_value_pp,
            post_purchase:this.state.post_purchase,
        }, ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState(
                    {
                        loading:false
                    }
                );
            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    componentWillMount(){
        this.Verify_shop();
    }


    render() {
        return(
            <>
                {this.state.loading ? <div className="loading">Loading...</div> :
                <Container fluid className="bg-light OBContainer">
                    <Row className="mvh-90 align-items-center justify-content-center ob-ohidden">

                        <Col xl={4} lg={5} md={8}>
                            <div className='text-center mt-40'><img src={Logo} alt="Cart Rescue" className="mb-30 h-40" /></div>
                            <Card className="card-ob">
                                <div className="ob-circle-1"></div>
                                <div className="ob-circle-2"></div>
                                <div className="ob-circle-3"></div>
                                <Card.Body>

                                    {/* <p className='wc-3 mw-100 text-left mb-30'>
                                        <p>
                                        {this.state.post_purchase === 1 ? 
                                        <Form.Check className="size-lg input-success" size="lg" type="switch" name="post_purchase" label="" value="0" onChange={this.handleChange} checked/>
                                        : <Form.Check className="size-lg input-success" size="lg" type="switch" name="post_purchase" label="" value="1" onChange={this.handleChange}/> }
                                        </p>

                                        <p className='mb-0'>
                                            <b>Post Purchase Discount</b> <br/>
                                            After a customer makes their first purchase on your store we can text them with a discount code for their next purchase to increase LTV and Returning Customer Rate
                                        </p>
                                    </p> */}

                                    

                                    {/* {this.state.post_purchase === 1 ? 
                                    <><h5 className='text-center mb-30'>What do you want the discount to be?</h5>
                                    <div className='rangeBubbleOutter'><span className="rangeBubble" style={{left:"calc("+this.state.rangeLeft+"% + "+this.state.lp+"px)"}}>{this.state.discount_value_pp}% Off</span></div>
                                    <Form.Range className='mb-20 ob-range' defaultValue={this.state.discount_value_pp} min="5" max="50" name="discount_value_pp" onChange={this.handleChange} /></>
                                    : "" } */}
                                    
                                    <p className='text-center'>Sample message</p>

                                    <div className="text-light text-center wc-4 mb-30">
                                        <div className="wc-4-inner">
                                            <span></span>
                                            <div className="wc-4-msgbox">
                                                Hey Jane, thank you for purchasing Backpack! Since you're a first time customer we'd like to offer you a <b>{this.state.discount_value_pp}%</b> discount for your next purchase. <br/> <br/>
                                                Here's the discount code: CR-XXXX <br/>
                                                Enjoy your purchase!
                                            </div>
                                        </div>
                                    </div>

                                    <div className='d-grid gap-2 wc-btn'>
                                        <NavLink to="/onboarding/contact-info" className="btn btn-ob btn-success btn-ob-success" onClick={this.Update_shop}  >
                                            Continue
                                        </NavLink>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                }
            </>
        );
    }

}

export default withRouter(OBDiscountPP);