const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export function nFormatter(num, digits) {
    if(!num){num=0;}
    
    if(num===0){
        return num;
    } else {
        const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
        });
        return (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol;
    }
}


export function initials(name){
    let initials = name.split(' ');
    if(initials.length > 1) {
      initials = initials.shift().charAt(0) + initials.pop().charAt(0);
    } else {
      initials = name.substring(0, 1);
    }
    return initials.toUpperCase();
}


export async function format_phone (phone) {
  let number = phoneUtil.parse(phone, 'US')
  const num = phoneUtil.format(number, PNF.E164);
  return num;
}


