import React, { Component} from 'react';
import axios from 'axios';
import { NavLink, withRouter } from 'react-router-dom';
import { Container, Col, Row, Button, Card, Form, Alert} from 'react-bootstrap';
import Logo from '../../assets/logo.svg'

import config from '../../config'
const TP_API_URL = config.TP_API_URL;

class OBSignup extends Component {
    state = {
        loading:true,
        disabled:false,
        user : {},
        error_code:0,
        error_msg:"",
        name:"",
        phone:"",
        email:"",
        password:""
    };

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    Register = (event) => {
        event.preventDefault();
        this.setState({
            error_code:0,
            error_msg:"",
            disabled:true
        });

        axios.post(TP_API_URL+'/client/users/sign-up', { 
            name:this.state.name,
            phone:this.state.phone,
            email:this.state.email,
            password:this.state.password,
            tp_shop_token: localStorage.getItem('tp_shop_token')
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                localStorage.setItem('tp_access_token', response.data.token);
                this.props.history.push("/authorize/"+localStorage.getItem('tp_shop_token'));
            } else {
                this.setState({
                    error_code:response.error_code,
                    error_msg:response.error_msg,
                    disabled:false
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    render() {
        return(
            <>
                <Container fluid className="bg-light">
                    <Row className="mvh-90 align-items-center justify-content-center ob-ohidden">

                        <Col xl={4} lg={5} md={8}>
                            <div className='text-center mt-40'><img src={Logo} alt="Cart Rescue" className="mb-30 h-40" /></div>
                            <Card className="card-ob">
                                <div className='ob-circle-1'></div>
                                <div className='ob-circle-2'></div>
                                <div className='ob-circle-3'></div>
                                <Card.Body>
                                    <Card.Title>Sign up</Card.Title>
                                    <Card.Subtitle className="mb-30 text-secondary">Register your account with Cart Rescue</Card.Subtitle>

                                    {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}

                                    <Form className='onboarding' onSubmit={this.Register}>
                                        <Form.Group className="mb-20" controlId="formBasicPassword">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text"  name="name" required={true} value={this.state.name} onChange={this.handleChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-20" controlId="formBasicPassword">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email"  name="email" required={true} value={this.state.email} onChange={this.handleChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-20" controlId="formBasicPassword">
                                            <Form.Label>Phone number</Form.Label>
                                            <Form.Control type="text"  name="phone" required={true} value={this.state.phone} onChange={this.handleChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-20" controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="text"  name="password" required={true} value={this.state.password} onChange={this.handleChange} />
                                        </Form.Group>
                                        
                                       
                                       <div className='d-grid gap-2'>
                                            <Button className="mb-20 btn-ob btn-ob-success" variant="success"  type="submit" disabled={this.state.disabled}>
                                               Continue
                                            </Button>
                                            <p className="text-center mb-0">Alreday have an account? <NavLink to="/onboarding/login" className='text-success ml-5'>Login</NavLink></p>
                                       </div>
                                        
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            
            </>
        );
    }

}

export default withRouter(OBSignup);