import React from "react";
import { NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars} from '@fortawesome/free-solid-svg-icons';
import { CiGrid42 } from 'react-icons/ci'
import { LiaComment } from 'react-icons/lia'
import { BiCog, BiCreditCard, BiLineChart } from 'react-icons/bi'
import { BsFunnel } from "react-icons/bs";
import { FiHeadphones, FiLogOut } from 'react-icons/fi'
import Logo from '../assets/logo.svg'


function hidenavbar(){
    document.body.classList.add('hidenav');
}

function shownavbar(){
    document.body.classList.remove('hidenav');
}

const Client = ({children}) =>{

    return(
        <div id="client" >
            <Button variant="light" onClick={shownavbar} size="sm" className="menu mr-5">
                <FontAwesomeIcon icon={faBars} className="menuicon" />
            </Button> 
            <div className="mobile-overlay" onClick={hidenavbar}></div>
            <section id="sidebar">
                <div className="navbar-wrapper">
                    <div className="brand">
                        <img src={Logo} alt="Cart Rescue" />
                    </div>
                    {/*<ShopList></ShopList>*/}
                    <div className="navbar">
                        <ul>
                            <li>
                                <NavLink exact to="/dashboard" onClick={hidenavbar}><CiGrid42 className="menuicon mr-12" /> Dashboard</NavLink>
                            </li>
                            <li>
                                <NavLink to="/dashboard/conversations" onClick={hidenavbar}><LiaComment className="menuicon mr-12" /> Conversations</NavLink>
                            </li>
                            <li>
                                <NavLink to="/dashboard/sales" onClick={hidenavbar} ><BiLineChart className="menuicon mr-12" /> Sales</NavLink>
                            </li>
                            {/* <li>
                                <NavLink to="/dashboard/orders" onClick={hidenavbar}><BiStore className="menuicon mr-12" /> Orders</NavLink>
                            </li> */}
                            <li>
                                <NavLink to="/dashboard/segments" onClick={hidenavbar}><BsFunnel className="menuicon mr-12" /> Segmentation</NavLink>
                            </li>
                            {/*
                            <li>
                                <NavLink to="/dashboard/events" onClick={hidenavbar}><FontAwesomeIcon icon={faCalendar} className="menuicon mr-12" /> Events</NavLink>
                            </li>
                            */}
    

                            <li className="divider"></li>
                            <li className="title">Account</li>

                            <li>
                                <NavLink to="/dashboard/settings" onClick={hidenavbar}><BiCog className="menuicon mr-12" /> Settings</NavLink>
                            </li>
                            {/* 
                            <li>
                                <NavLink to="/dashboard/collaborate" onClick={hidenavbar}><FontAwesomeIcon icon={faHandshake} className="menuicon mr-12" /> Collaborate</NavLink>
                            </li>
                            */}
    
                            <li>
                                <NavLink to="/dashboard/billing" onClick={hidenavbar}><BiCreditCard className="menuicon mr-12" /> Billing</NavLink>
                            </li>
    
                           
                            <li>
                                <NavLink to="/dashboard/support" onClick={hidenavbar}><FiHeadphones className="menuicon mr-12" /> Support</NavLink>
                            </li>
    
                            
                            <li>
                                <NavLink to="/dashboard/logout" onClick={hidenavbar}><FiLogOut className="menuicon mr-12" /> Logout</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id="content">{children}</section>
        </div>
    )
}

export default Client;