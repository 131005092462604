import React, { Component} from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Container, Row, Button, Form } from 'react-bootstrap';
import Logo from '../../assets/logo.svg'
import config from '../../config'

const TP_API_URL = config.TP_API_URL;
const BASE_URL = config.BASE_URL;


class OBPlans extends Component {

    state = {
        loading:true,
        error_code:"Loading",
        error_msg:"verifying your request",
        shop_data:[],
        user_data:[],
        promocode:"",
        apply_promo:0,
        promo_error_code:0,
        promo_error_msg:"",
    };

    verify_token = () => {
        const token = this.props.match.params.token;
        //set token to localstorage.
        localStorage.setItem('tp_shop_token', token);

        const ax_headers = {
            headers: {
                token: token
            }
        }

        if(token){
            axios.post(TP_API_URL+"client/shop/verify-token", "", ax_headers)
            .then((res) => {
                const response = res.data;
                if(response.error_code===0){
                    //verified
                    this.setState(
                        {
                            loading:false,
                            error_code:response.error_code,
                            error_msg:response.error_msg
                        }
                    );
                } else {
                    this.setState(
                        {
                            loading:true,
                            error_code:"Error",
                            error_msg:"Something went wrong"
                        }
                    );
                }
                
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
        }
    }

    componentWillMount(){
        this.verify_token();
    }

    SelectPlan = (id) => {
        //console.log(id);
        const token = this.props.match.params.token;
        const {promocode} = this.state;
        window.location.href = BASE_URL+"app/shopify/createsubscription?token="+token+"&plan_id="+id+"&promocode="+promocode;
        //<a href={BASE_URL+"app/shopify/createsubscription?token="+token+"&plan_id=3"} className="btn btn-block btn-yellow">Select</a>
    }

    Showpromo = (id) => {
        this.setState(
            {
                apply_promo : id
            }
        );
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    applyPromo = (plan) => {
        const {promocode} = this.state;
        if(promocode){
            this.setState(
                {
                    promo_error_code:0,
                    promo_error_msg:""
                }
            );

            const token = this.props.match.params.token;
            const ax_headers = {
                headers: {
                    token: token
                }
            }
            axios.post(BASE_URL+"app/shopify/promo-verify", {
                promocode : promocode,
                plan: plan
            }, ax_headers)
            .then((res) => {
                const response = res.data;
                if(response.discount_value){
                    //verified
                    this.setState(
                        {
                            promo_error_code:2,
                            promo_error_msg:response.error_msg
                        }
                    );
                } else {
                    this.setState(
                        {
                            promo_error_code:response.error_code,
                            promo_error_msg:response.error_msg
                        }
                    );
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
        }
    }

    removePromo = () => {
        this.setState(
            {
                promo_error_code:0,
                promo_error_msg:"",
                promocode:""
            }
        );
    }

    render() {
        return(
            <>
                {this.state.loading ? <div className="loading">Loading...</div> :
                <section id="pricing">
                    <div className="container pricing-heading">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img src={Logo} alt="Cart Rescue" className="h-40" />
                            </div>
                        </div>
                    </div>
                    <Container className="pricing-table">
                        <Row className="row justify-content-md-center">
                            <div className="col-md-6 col-lg-3 mb-20">
                                <div className="h100 plans">
                                    <div className="pch">Starter</div>
                                    <div className="plans-price">
                                        <h3><b>$0</b>/month</h3>
                                        <span>15% of CartRescue sales</span>
                                    </div>
                                    <div className='pl-features'>
                                        <ul>
                                            <li>Real Human Reps</li>
                                            <li>SMS Campaigns</li>
                                            <li>Real Time Analytics</li>
                                            <li>Customer Chat Support</li>
                                        </ul>
                                    </div>

                                    
                                    <div className="mt-auto mb-10">
                                        <Button className='btn btn-success btn-ob btn-bo-success' onClick={()=>{this.SelectPlan(1)}}>Select</Button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3 mb-20">
                                <div className="h100 plans plans-dark">
                                    <div className='tag-wrapper'><div className='tag'>Most Popular</div></div>
                                    <div className="pch">Growth</div>
                                    <div className="plans-price">
                                        <h3><b>$49</b>/month</h3>
                                        <span>7.5% of CartRescue sales</span>
                                    </div>
                                    <div className='pl-features'>
                                        <ul>
                                            <li>Real Human Reps</li>
                                            <li>SMS Campaigns</li>
                                            <li>Real Time Analytics</li>
                                            <li>Customer Chat Support</li>
                                        </ul>
                                    </div>

                                    
                                    <div className="mt-auto mb-10">
                                        {this.state.apply_promo===2 ?
                                            this.state.promo_error_code!==2 ?
                                            <div className='promo-code'>
                                                {this.state.promo_error_code===1 ? <p className='text-danger text-center'>{this.state.promo_error_msg}</p> : "" }
                                                <Form.Control type="text" placeholder="Enter your promo code" name="promocode" value={this.state.promocode} onChange={this.handleChange} />
                                                <Button className='btn btn-light w-100 my-2' onClick={()=>{this.applyPromo(2)}}>Apply</Button>
                                            </div>
                                            : 
                                                <p className='text-center text-success'>{this.state.promocode} - {this.state.promo_error_msg} <br></br> 
                                                    <button className='btn btn-link w-100 text-light text-decoration-underline mb-10' onClick={()=>{this.removePromo()}}>Remove</button>
                                                </p>
                                            :
                                            <button className='btn btn-link w-100 text-light text-decoration-underline mb-10' onClick={()=>{this.Showpromo(2)}}>Apply promo code</button>
                                        }
                                        <Button className='btn btn-success btn-ob btn-ob-success' onClick={()=>{this.SelectPlan(2)}}>Select</Button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3 mb-20">
                                <div className="h100 plans">
                                    <div className='tag-wrapper'><div className='tag'>Best value</div></div>
                                    <div className="pch">Pro</div>
                                    <div className="plans-price">
                                        <h3><b>$299</b>/month</h3>
                                        <span>2.5% of CartRescue sales</span>
                                    </div>
                                    <div className='pl-features'>
                                        <ul>
                                            <li>Real Human Reps</li>
                                            <li>SMS Campaigns</li>
                                            <li>Real Time Analytics</li>
                                            <li>Customer Chat Support</li>
                                        </ul>
                                    </div>

                                    
                                    <div className="mt-auto mb-10">
                                        {this.state.apply_promo===3 ?
                                            this.state.promo_error_code!==2 ?
                                            <div className='promo-code'>
                                                {this.state.promo_error_code===1 ? <p className='text-danger text-center'>{this.state.promo_error_msg}</p> : "" }
                                                <Form.Control type="text" placeholder="Enter your promo code" name="promocode" value={this.state.promocode} onChange={this.handleChange} />
                                                <Button className='btn btn-dark btn-pricing-dark w-100 my-2' onClick={()=>{this.applyPromo(3)}}>Apply</Button>
                                            </div>
                                            : 
                                                <p className='text-center text-success'>{this.state.promocode} - {this.state.promo_error_msg} <br></br> 
                                                    <button className='btn btn-link w-100 text-dark text-decoration-underline mb-10' onClick={()=>{this.removePromo()}}>Remove</button>
                                                </p>
                                            :
                                            <button className='btn btn-link w-100 text-dark text-decoration-underline mb-10' onClick={()=>{this.Showpromo(3)}}>Apply promo code</button>
                                        }
                                        <Button className='btn btn-success btn-ob btn-bo-success' onClick={()=>{this.SelectPlan(3)}}>Select</Button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3 mb-20">
                                <div className="h100 plans">
                                    <div className="pch">Enterprise</div>
                                    <div className="plans-price">
                                        <h3><b>$999</b>/month</h3>
                                        <span>1% of CartRescue sales</span>
                                    </div>
                                    
                                    <div className='pl-features'>
                                        <ul>
                                            <li>Real Human Reps</li>
                                            <li>SMS Campaigns</li>
                                            <li>Real Time Analytics</li>
                                            <li>Customer Chat Support</li>
                                        </ul>
                                    </div>

                                    
                                    <div className="mt-auto mb-10">
                                        {this.state.apply_promo===4 ?
                                            this.state.promo_error_code!==2 ?
                                            <div className='promo-code'>
                                                {this.state.promo_error_code===1 ? <p className='text-danger text-center'>{this.state.promo_error_msg}</p> : "" }
                                                <Form.Control type="text" placeholder="Enter your promo code" name="promocode" value={this.state.promocode} onChange={this.handleChange} />
                                                <Button className='btn btn-dark btn-pricing-dark w-100 my-2' onClick={()=>{this.applyPromo(4)}}>Apply</Button>
                                            </div>
                                            : 
                                                <p className='text-center text-success'>{this.state.promocode} - {this.state.promo_error_msg} <br></br> 
                                                    <button className='btn btn-link w-100 text-dark text-decoration-underline mb-10' onClick={()=>{this.removePromo()}}>Remove</button>
                                                </p>
                                            :
                                            <button className='btn btn-link w-100 text-dark text-decoration-underline mb-10' onClick={()=>{this.Showpromo(4)}}>Apply promo code</button>
                                        }
                                        <Button className='btn btn-success btn-ob btn-bo-success' onClick={()=>{this.SelectPlan(4)}}>Select</Button>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </section>
                }
            </>
        );
    }

}

export default withRouter(OBPlans);