import React, {Component} from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const datasetKeyProvider=()=>{ 
  return btoa(Math.random()).substring(0,12)
} 


const options = {
  plugins:{
    datalabels: {
      color: '#333',
      display: 'auto',
      align: 'end',
      anchor: 'end',
      font: {
        weight: 'bold'
      },
      formatter: function(value, context) {
        return value.y.toFixed(0)+"%";
      }
    }
  },
  legend: {
      display: false
  },
  tooltips: {
      custom: function(tooltip) {
        if (!tooltip) return;
        tooltip.displayColors = false;
      },
      callbacks: {
          label: function(tooltipItem, data) {
              var t = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].t.toFixed(0);
              var r = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].r.toFixed(0);
              return ["Total conversations: "+t, "Recovered: "+r];
          },
          title: function(tooltipItem, data) {
            return;
          }
      }
  },
  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: '% of orders recovered'
      },
      afterDataLimits(scale) {
        var range = scale.max-scale.min;
        var grace = range * 0.1; 
        scale.max += grace ;
        scale.min -= grace;
      }
    }]
  }
}

class Recovery extends Component {

  
    state = {
      data: {},
      loading:false
    };

    getchartdata = async () => {
      let data = this.props.data;
      this.setState({
        data: {
          labels: data.xaxis,
          datasets: [
            {
              label: "",
              fill: true,
              lineTension: 0.4,
              backgroundColor: 'rgba(207,234,252,0.5)',
              borderColor: 'rgba(134,204,255,1)',
              borderCapStyle: 'butt',
              borderDash: [5],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(134,204,255,1)',
              pointBackgroundColor: '#ffffff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgba(134,204,255,1)',
              pointHoverBorderColor: 'rgba(134,204,255,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 5,
              pointHitRadius: 10,
              data: data.yaxis
            }
          ]
        },
        loading: false
      });
      
    }


    componentDidMount() {
      this.getchartdata();
    }


    componentDidUpdate(prevProps) {
      if (this.props.data !== prevProps.data) {
        this.getchartdata();
      }
    }


    

    render() {
      return (
        <div className="chartwrapper">
          <Line ref="chart" data={this.state.data} options={options} height={100} className="areaChart" datasetKeyProvider={datasetKeyProvider} />
        </div>
      );
    }
}


export default Recovery;