import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Logo from '../../assets/logo.svg'
import Pdf from "react-to-pdf";
import config from '../../config'
const TP_API_URL = config.TP_API_URL;
var dateFormat = require("dateformat");
let source = axios.CancelToken.source();


const ref = React.createRef();

class PdfInvoice extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        loading:true,
        loading_text:"Loading...",
        data:[{}]
    };

    getData = () => {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        const id = this.props.match.params.id;

        this.setState({tableloading:true});
        if (source) { source.cancel();}
        source = axios.CancelToken.source();
        axios.get(TP_API_URL+"client/invoices/view", {
            params:{shop_id:tp_shop, id:id},
            headers:{token: tp_access_token},
            cancelToken: source.token
        }).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                this.setState({
                    loading:false,
                    data:response.data
                });
            } else {
                this.setState({
                    loading_text:response.error_msg
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
            this.setState({
                loading_text:e.message
            });
        });
    }


    componentDidMount(){
        this.getData();
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }

    render(){
        return ( 
            this.state.loading ? <div className='loading2 mt-20'><p>{this.state.loading_text}</p></div> :
            <>
                <div style={{width:'210mm', margin:'20px auto', textAlign:'right'}}>
                    <Pdf targetRef={ref} filename={this.state.data.filename}>
                        {({ toPdf }) => <Button onClick={toPdf}>Generate Pdf</Button>}
                    </Pdf>
                </div>
                <div style={{width:'210mm', height:'297mm', border:'1px solid #ddd', margin:'20px auto', padding:'30px'}} ref={ref}>
                    <table style={{width:'100%'}}>
                        <tbody>
                            <tr>
                                <td style={{paddingBottom:'20px', textAlign:'left', verticalAlign:'top'}}>
                                    <img src={Logo} alt="Cart Rescue" />
                                </td>

                                <td style={{paddingBottom:'20px', textAlign:'right', verticalAlign:'top'}}>
                                    <b>Invoice</b><br/>
                                    Invoice Number: {this.state.data.number} <br/>
                                    Date:{dateFormat(this.state.data.date, "fullDate")}<br/>
                                </td>
                                
                            </tr>
                            <tr>
                                <td style={{paddingBottom:'20px'}}>
                                    <b>Cart Rescue</b><br/>
                                    127 35th st 1/2,<br/>
                                    Newport Beach, 92663<br/>
                                    United States<br />
                                    contact@cartrescue.io
                                </td>
                                <td style={{paddingBottom:'20px', textAlign:'right', verticalAlign:'top'}}>
                                    <b>{this.state.data.shop_name}</b><br/>
                                    {this.state.data.shop_email}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table style={{width:'100%', marginTop:'30px'}}>
                        <thead>
                            <tr>
                                <th style={{borderBottom:'2px solid #333', padding:'10px 0'}}>Description</th>
                                <th style={{borderBottom:'2px solid #333', width:'100px', textAlign:'right', padding:'10px 0'}}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{padding:'30px 0 5px'}}>Cart Rescue Pro</td>
                                <td style={{textAlign:'right', padding:'30px 0 5px'}}>$49</td>
                            </tr>
                            <tr>
                                <td style={{padding:'5px 0 30px'}}>{this.state.data.line_desc}</td>
                                <td style={{textAlign:'right', padding:'5px 0 30px'}}>{this.state.data.line_amount}</td>
                            </tr>
                            <tr>
                                <td style={{borderTop:'1px solid #333', textAlign:'right', padding:'10px 0', fontWeight:'bold'}}>Total</td>
                                <td style={{borderTop:'1px solid #333', textAlign:'right', padding:'10px 0', fontWeight:'bold'}}>{this.state.data.total}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>&nbsp;</div>
            </>
        );
    }

}

export default withRouter(PdfInvoice);