import React, { Component} from 'react';
import axios from 'axios';
import { NavLink, withRouter } from 'react-router-dom';
import { Container, Col, Row, Button, Card, Form, Alert} from 'react-bootstrap';
import Logo from '../../assets/logo.svg'
import {IoPersonOutline} from 'react-icons/io5';
import config from '../../config'

const TP_API_URL = config.TP_API_URL;

class OBLogin extends Component {

    state = {
        loading:true,
        disabled:false,
        layout:0,
        user : {},
        error_code:0,
        error_msg:"",
        email:"",
        password:""
    };

    verify_token = () => {
        const token = localStorage.getItem('tp_access_token');

        if(token){
            const ax_headers = {
                headers: {
                    token: token
                }
            }

            axios.get(TP_API_URL+"client/users/view", ax_headers)
            .then((res) => {
                const response = res.data;
                if(response.error_code===0){
                    this.setState(
                        {
                            loading:false,
                            layout:1,
                            user : response.data
                        }
                    );
                } else {
                    this.setState(
                        {
                            loading:false,
                            layout:2
                        }
                    );
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
        } else {
            this.setState({layout:2, loading:false});
        }
    }

    LoginToDifferent = () => {
        this.setState({
            error_code:0,
            error_msg:"",
            layout:2
        });
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    Login = (event) => {
        event.preventDefault();
        this.setState({
            error_code:0,
            error_msg:"",
            disabled:true
        });

        axios.post(TP_API_URL+'/client/users/access-token', { 
            email:this.state.email,
            password:this.state.password,
            tp_shop_token: localStorage.getItem('tp_shop_token')
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                localStorage.setItem('tp_access_token', response.data.token);
                this.props.history.push("/authorize/"+localStorage.getItem('tp_shop_token'));
            } else {
                this.setState({
                    error_code:response.error_code,
                    error_msg:response.error_msg,
                    disabled:false
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    Continue = (event) => {
        event.preventDefault();
        const token = localStorage.getItem('tp_access_token');
        const ax_headers = { headers: { token: token } }

        this.setState({
            error_code:0,
            error_msg:"",
            disabled:true
        });

        axios.post(TP_API_URL+'/client/shop/add-user', {
            shop_token: localStorage.getItem('tp_shop_token')
        }, ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.props.history.push("/authorize/"+localStorage.getItem('tp_shop_token'));
            } else {
                this.setState({
                    error_code:response.error_code,
                    error_msg:response.error_msg,
                    disabled:false
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    componentWillMount(){
        this.verify_token();
        this.setState({
            error_code:0,
            error_msg:""
        });
    }


    render() {
        return(
            <>
                {this.state.loading ? <div className="loading">Loading...</div> :
                <Container fluid className="bg-light">
                    <Row className="mvh-90 align-items-center justify-content-center ob-ohidden">
                        {this.state.layout===1 ? 
                        <Col xl={4} lg={5} md={8}>
                            <div className='text-center mt-40'><img src={Logo} alt="Cart Rescue" className="mb-30 h-40" /></div>
                            <Card className="card-ob">
                                <div className='ob-circle-1'></div>
                                <div className='ob-circle-2'></div>
                                <div className='ob-circle-3'></div>
                                <Card.Body>
                                    <Card.Title>Log in</Card.Title>
                                    <Card.Subtitle className="mb-30 text-secondary">continue to Cart Rescue as</Card.Subtitle>
                                    {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}
                                    <Card body className='mb-20'>
                                        <Container>
                                            <Row>
                                                <Col xs={"auto"} className="OBicon">
                                                    <IoPersonOutline/>
                                                </Col>
                                                <Col>
                                                    <h5>{this.state.user.name} <br/> <small>{this.state.user.email}</small></h5>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Card>

                                    <div className='d-grid gap-2'>
                                        <Button className="btn-ob btn-ob-success" variant="success"  type="button" onClick={this.Continue} disabled={this.state.disabled}>
                                            Continue
                                        </Button>
                                        <Button className="mb-20 btn-ob" variant="light"  type="button" onClick={this.LoginToDifferent}>
                                            Log in to a different account
                                        </Button>
                                        <p className="text-center">New to CartRescue? <NavLink to="/onboarding/register" className='text-success ml-5'>Register now</NavLink></p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        : "" }
                        {this.state.layout===2 ? 
                        <Col xl={4} lg={5} md={8}>
                            <div className='text-center mt-40'><img src={Logo} alt="Cart Rescue" className="mb-30 h-40" /></div>
                            <Card className="card-ob">
                                <div className='ob-circle-1'></div>
                                <div className='ob-circle-2'></div>
                                <div className='ob-circle-3'></div>
                                <Card.Body>
                                    <Card.Title>Log in</Card.Title>
                                    <Card.Subtitle className="mb-30 text-secondary">Continue to Cart Rescue</Card.Subtitle>

                                    {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}

                                    <Form className='onboarding' onSubmit={this.Login}>
                                        <Form.Group className="mb-20" controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" name="email" required={true} value={this.state.email} onChange={this.handleChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-20" controlId="formBasicPassword">
                                            <Form.Label className="w-100">Password <NavLink to="/onboarding/forgot" className='float-end text-success'>Forgot password?</NavLink></Form.Label>
                                            <Form.Control type="password" name="password" required={true}  onChange={this.handleChange} />
                                        </Form.Group>
                                       
                                       <div className='d-grid gap-2'>
                                            <Button className="mb-20 btn-ob btn-ob-success" variant="success"  type="submit" disabled={this.state.disabled}>
                                                Sign in
                                            </Button>
                                            <p className="text-center">New to CartRescue? <NavLink to="/onboarding/register" className='text-success ml-5'>Register now</NavLink></p>
                                       </div>
                                        
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        : "" }
                    </Row>
                </Container>
                }
            
            </>
        );
    }

}

export default withRouter(OBLogin);