import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { TP_API_URL } from '../../config';
import { SegmentForm } from '../../components/SegmentForm';

const initialCondition = {
    field: 'customer type',
    operator: 'is',
    value: 'first time customer',
    connector: 'OR'
};

class SegmentsNew extends Component {
    
    state = {
        loading: true,
        loading_text: "Loading...",
        segment: {
            id: null,
            name: "",
            action: "offer_discount",
            discount_type: "percentage",
            discount_value: 30,
            rules: [initialCondition],
        }
    };

    componentDidMount() {
        this.setState({ loading: false });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    createSegment = async () => {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
    
        try {
            const response = await axios.post(`${TP_API_URL}client/segments`, {
                name: this.state.segment.name,
                action: this.state.segment.action,
                discount_type: this.state.segment.discount_type,
                discount_value: Number(this.state.segment.discount_value),
                rules: this.state.segment.rules
            }, {
                headers: { token: tp_access_token },
                params: { shop_id: tp_shop },
            });
    
            const responseData = response.data;

            if(response.error_code===401){
                this.props.history.push("/login");
            }
            else if (responseData.success) {
                this.setState({
                    loading_text: 'Segment created successfully.'
                });
                setTimeout(() => {
                    this.setState({
                        loading_text: 'Redirecting to segments page...'
                    });
                }, 100);
                setTimeout(() => {
                    this.props.history.push('/dashboard/segments');
                }, 100);
            } else {
                this.setState({
                    loading_text: 'Error creating segment.'
                });
            }
        } catch (error) {
            this.setState({
                loading_text: error.message
            });
        }
    }

    setSegment = (updatedSegment) => {
        this.setState({ segment: updatedSegment });
    };

    render() {
        const { loading, loading_text } = this.state;

        return loading ? (
            <div className='loading2'><p>{loading_text}</p></div>
        ) : (
            <SegmentForm 
                isEditing={false} 
                onCreate={this.createSegment}
                segment={this.state.segment}
                setSegment={this.setSegment}
                onBack={this.goBack}
            />
        );
    }
}

export default withRouter(SegmentsNew);
