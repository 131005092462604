import React, {Component} from 'react';
import { Funnel } from 'react-kvn-funnel';


class CFunnel extends Component {


    state = {
        data: [{
            "label": "Initiated conversations",
            "percent": 0,
            "quantity": 100
        },
        {
            "label": "Replied",
            "percent": 0,
            "quantity": 75
        },
        {
            "label": "Purchased",
            "percent": 0,
            "quantity": 25
        }],
        loading:true
    };
  
    getchartdata = async () => {
        this.setState({loading:true});
        let data = this.props.data;
        console.log(data);
        this.setState({
            data:[
                {
                    "label": "Initiated conversations",
                    "percent": 0,
                    "quantity": data.total,
                },
                {
                    "label": "Replied",
                    "percent": 0,
                    "quantity": data.replied,
                },
                {
                    "label": "Purchased",
                    "percent": 0,
                    "quantity": data.recoverd,
                }
            ],
            loading: false
        });
    }

    componentDidMount() {
        this.getchartdata();
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.getchartdata();
        }
    }


    render() {
        return (
          <div className="chartwrapper">
                {this.state.loading ? <div className="loading">Loading...</div> : 
                <Funnel 
                  height={150}
                  width={300}
                  colors={{
                    graph: [ '#E2F1FA', '#86D0FC' ],
                  }}
                  labelKey='label'
                  valueKey='quantity'
                  displayLabel={true}
                  responsive={true}
                  data={this.state.data}
                  displayPercent={true}
                />
                }
          </div>
        );
    }

}


export default CFunnel;