import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Container, Col, Row, Card, Alert} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import {initials} from "../../helper/General";
import config from '../../config'
import { MdThumbDown, MdThumbUp } from 'react-icons/md';
const TP_API_URL = config.TP_API_URL;
var dateFormat = require("dateformat");
let source = axios.CancelToken.source();



class ConversationsView extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        loading:true,
        loading_text:"Loading...",
        sms:[{}],
        conversations:[{}],
        error_code:0,
        error_msg:"",
        rated:0,
        rating:0,
        comment:"",
        success:0,
        disabled:false
    };

    

    getData = () => {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        const id = this.props.match.params.id;

        if (source) { source.cancel();}
        source = axios.CancelToken.source();
        

        axios.get(TP_API_URL+"client/conversations/view", {
            params:{shop_id:tp_shop, id:id},
            headers:{token: tp_access_token},
            cancelToken: source.token
        }).then((res) => {
        const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                this.setState({
                    loading:false,
                    sms:response.sms,
                    conversations:response.conversations,
                    rating:response.conversations.rating
                });
            } else {
                this.setState({
                    loading_text:response.error_msg
                });
            }
        })
        .catch((e) => {
            this.setState({
                loading_text:e.message
            });
        });
    }

    componentDidMount(){
        this.getData();
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }

    GoBack = () => {
        this.props.history.goBack();
    }

    CreateChat = () => {
        let cdata = [];
        for (var i = 0; i < this.state.sms.length; i++) {
            var fdata = this.state.sms[i];
            var self = "self";
            var name = "Amber";
            if(fdata.sms_received===1){
                self="";
                name=this.state.conversations.cart_name;
            }
            cdata.push(
                <div className={"message " + self}>
                    <div className="message-options">
                        <span className="message-date">{dateFormat(fdata.addedon, "h:MM TT")}</span>
                    </div>
                    <div className="message-wrapper">
                        {fdata.sms_received===1 ?
                        <>
                            <div className={"avatar avatar-sm"}>
                                <span>{initials(name)}</span>
                            </div>
                            <div className="message-content">
                                <span>{fdata.sms_body}</span>
                            </div>
                            
                        </>

                        : 

                        <>
                            <div className="message-content">
                                <span>{fdata.sms_body}</span>
                            </div>
                            <div className={"avatar avatar-sm"}>
                                <span>{initials(name)}</span>
                            </div>
                        </>

                        }
                    </div>
				</div>
            );
        }
        return cdata;
    }

    rateup = () => {
        this.setState({
            rating:1,
            rated:1,
            success:0,
            comment:"",
            disabled:false
        });

        this.updateconversation(1);
    }

    ratedown = () => {
        this.setState({
            rating:2,
            rated:1,
            success:0,
            comment:"",
            disabled:false
        });

        this.updateconversation(2);
    }

    sendfeedback = (event) => {
        event.preventDefault();
        this.setState({disabled:true});
        this.updateconversation(this.state.rating);
    }

    handleChange = async (event) => {
        const target = event.target;
        const value = target.value;
        this.setState({
            comment: value
        });
        
    }

    updateconversation = (rating) =>{
        
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        const id = this.props.match.params.id;
        if (source) { source.cancel();}
        source = axios.CancelToken.source();

        const ax_headers = {
            headers: {
                token: tp_access_token
            }
        }

        axios.post(TP_API_URL+'client/conversations/update', { 
            rating:rating,
            rating_comment:this.state.comment,
            shop_id:tp_shop,
            id:id
        }, ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0 && response.comment===1){
                this.setState({
                    error_code:response.error_code,
                    error_msg:response.error_msg,
                    success:1,
                    rated:0,
                    comment:""
                });
                
            } else {
                this.setState({
                    error_code:response.error_code,
                    error_msg:response.error_msg
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    render() {
        
        return(
            this.state.loading ? <div className='loading2'><p>{this.state.loading_text}</p></div> :
            <>
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <h3 className="page-heading sidebar-btn" onClick={this.GoBack}>
                                <FontAwesomeIcon icon={faArrowLeft} className="menuicon mr-5" /> {this.state.conversations.cart_name}
                            </h3>
                        </Col>
                        <Col sm={8}>
                            <Card className="vh80">
                                <Card.Body>
                                    <div className="chat-body">
                                        <div className="chat-content p-2" id="messageBody">
                                            {this.CreateChat()}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            
                        </Col>

                        <Col sm={4}>
                            <Card className="vh80">
                                <Card.Body className='p-25'>
                                    <h3 className="table-heading mt-0">
                                        Details
                                    </h3>
                                    <h6>User Information</h6>
                                    <p><small className="text-muted">Name:</small><br />{this.state.conversations.cart_name}</p>
                                    <p><small className="text-muted">Phone:</small><br />{this.state.conversations.sms_to}</p>
                                    <p><small className="text-muted">Contacted:</small><br />{dateFormat(this.state.conversations.updated_on)}</p>
                                    <p><small className="text-muted">Total messages:</small><br />{this.state.conversations.sms_total}</p>
                                    <p>
                                        <small className="text-muted">Order #:</small><br />
                                        <a 
                                            href={"https://"+this.state.conversations.main_domain+"/admin/orders/"+this.state.conversations.order_id} 
                                            target="_blank" 
                                            style={{color: 'green'}} rel="noreferrer"
                                        >
                                            {this.state.conversations.order_name}
                                        </a>
                                    </p>
                                    <p><small className="text-muted">Order amount:</small><br />{this.state.conversations.order_total} USD</p>
                                    <p><small className="text-muted">Status:</small><br /><span className={'cart-status ' + this.state.conversations.cart_status}>{this.state.conversations.cart_status}</span></p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col sm={12}>
                            <Card className="my-2 text-center">
                                <Card.Body>
                                    <h5 className='table-heading mt-0'>Rate this conversation</h5>
                                    <div>
                                        <MdThumbUp className={this.state.rating===1 ? "rate-thumb active" : "rate-thumb"} onClick={this.rateup} />
                                        <MdThumbDown className={this.state.rating===2 ? "rate-thumb active" : "rate-thumb"} onClick={this.ratedown}/>
                                    </div>

                                    {this.state.success > 0 ? <Alert variant="success">Thank you for your feedback!</Alert> : ""}
                                    {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}

                                    {/*this.state.rated===1 ?
                                    <div>
                                        <Form onSubmit={this.sendfeedback}>
                                            <InputGroup>
                                                <Form.Control as="textarea" aria-label="With textarea" name="rating_comment" placeholder="Your feedback" required={true} onChange={this.handleChange} />
                                                <Button variant="outline-secondary" id="button-addon2" type="submit" disabled={this.state.disabled}>
                                                    <FontAwesomeIcon icon={faPaperPlane} className="mr-5"/> Send
                                                </Button>
                                            </InputGroup>
                                        </Form>
                                    </div>

                                    : "" */ }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        
        )
    }
}

export default withRouter(ConversationsView);