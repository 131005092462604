import React, { Component} from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';


class Support extends Component {
    render(){
        return (
            <>
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <h3 className="page-heading sidebar-btn mb-0">
                                Support
                            </h3>
                        </Col>
                    </Row>
                </Container>

                <Container fluid>
                    <Row>
                        <Col lg={9} className='support'>
                        <h5 className='mt-10 mb-20'>
                            Need to contact us? We’re here to help! <br />
                            <small>Get in touch with us via email <a href='mailto:support@cartrescue.io' className='text-primary fw-bold'>support@cartrescue.io</a> or read some frequently asked support questions below.</small>
                        </h5>
                        <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Why do I need an abandoned cart recovery service?</Accordion.Header>
                                    <Accordion.Body>
                                        Think about the way you shop online - you open a store, shop around, add loads of products to your cart and leave the website without placing an order. That’s how 70% of all consumers online act. Now imagine what your sales might look like if you could recover 20% of those abandoned carts. That’s Cart Rescue – we recover abandoned carts, and help you generate more profit.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Why opt for SMS messages instead of emails?</Accordion.Header>
                                    <Accordion.Body>Both have their place in the ecommerce ecosystem and play well together. Emails average less than a 20% open rate and 5% abandoned cart recovery rate. SMS on the other hand averages over 95% open rates and 4x recovery rates vs email. Your average person doesn’t even see an email for 90 minutes vs 90 seconds for SMS! All in all, SMS is a proven method of regenerating abandoned carts. Stay top of mind with Cart Rescue.</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>How does it work?</Accordion.Header>
                                    <Accordion.Body>After your potential buyer leaves the store without placing an order they’ll be notified via SMS that their cart is saved and then enticed to come back by one of our agents with a small discount code.</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>How do I make sure my customers aren’t annoyed?</Accordion.Header>
                                    <Accordion.Body>We reach out to your customer 1 time and 1 time only. If you’re customer abandons a second cart a month from now we won’t send them a second cart recovery text. We run validations in our database to exclude numbers we already talked to. We do this to ensure we’re compliant with major carriers like T-mobile and so that your company keeps happy customers.</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>What time of day do you message my customers?</Accordion.Header>
                                    <Accordion.Body>We’re online 15 hours a day 7 days a week recovering your sales. Your customers won’t get texts during the TCPA-mandated quiet period, which is anytime before 8am and after 9pm in their timezone.</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Is there a guarantee my sales will be recovered?</Accordion.Header>
                                    <Accordion.Body>We’ll give you a 14 day free trial. You’ll be able to see whether CartRescue works for you, and how efficient it is for your store without being charged a single cent of commission.</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>How much is your service?</Accordion.Header>
                                    <Accordion.Body>We offer various subscription options that can be found on our <a href="https://cartrescue.io/pricing" target="_blank" rel="noreferrer" className='text-primary'>Pricing page</a>. If you have any questions feel free to email us at <a href='mailto:support@cartrescue.io' className='text-primary'>support@cartrescue.io</a> or hit the live chat button in the bottom right hand corner!</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>How often will I be charged for your abandoned shopping cart recovery service?</Accordion.Header>
                                    <Accordion.Body>You will be charged for our abandoned cart recovery service every 30 days and billing will be assessed based on the value of the sales recovered during that month.</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>How do I know your service is working?</Accordion.Header>
                                    <Accordion.Body>Through our dashboards you can track each recovered order which links directly back to Shopify orders. We only claim credit for sales that use our dynamic discounts or click the unique cart link provided.</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>How will my customers be encouraged to get back to their cart and place an order?</Accordion.Header>
                                    <Accordion.Body>Our team starts a conversation with your customers via sms, answers any questions and offers a small one-time-use discount to get them across the finish line. A custom link back to their cart will drop them right off where they left off.</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                    <Accordion.Header>How do I contact you?</Accordion.Header>
                                    <Accordion.Body>If you have any questions about our abandoned cart recovery service, drop us a line at <a href="mailto:support@cartrescue.io" className='text-primary'>support@cartrescue.io</a> or hit the live chat button in the bottom right hand corner!</Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="11">
                                    <Accordion.Header>Where are you guys located?</Accordion.Header>
                                    <Accordion.Body>Deep in the heart of Texas!</Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Support;