import React, { Component} from 'react';
import { Container, Col, Row, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import iCollaborate from '../../assets/images/client/collaborate.png'
import config from '../../config'
const TP_API_URL = config.TP_API_URL;
let source = axios.CancelToken.source();

class Collaborate extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        loading:false,
        loading_text:"Loading...",
        disabled:false,
        success:0,
        error_code:0,
        error_msg:"",
        topic:"",
        message:""
    };

    handleChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    sendMsg = (event) =>{
        event.preventDefault();
        
        this.setState({
            error_code:0,
            error_msg:"",
            success:0,
            disabled:true
        });
        
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        if (source) { source.cancel();}
        source = axios.CancelToken.source();

        const ax_headers = {
            headers: {
                token: tp_access_token
            }
        }

        axios.post(TP_API_URL+'client/collaborate/update', { 
            shop_id:tp_shop,
            topic:this.state.topic,
            message:this.state.message
        }, ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState({
                    error_code:response.error_code,
                    error_msg:response.error_msg,
                    success:1,
                    topic:"",
                    message:"",
                    disabled:false
                });
            } else {
                this.setState({
                    error_code:response.error_code,
                    error_msg:response.error_msg,
                    disabled:false
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    render(){
        return (
            <>
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <h3 className="page-heading sidebar-btn">
                                Collaborate
                            </h3>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <Col lg={8}>
                            <img src={iCollaborate} alt="Collaborate" width="100" height="100" className='mb-20' />
                            <h5>Collaborate Knowledge Base</h5>
                            <p>Share information with Cart Rescue agents. If you have anything you'd like us to know when conversating with your customers, Let us know below.</p>

                            {this.state.success > 0 ? <Alert variant="success">We have received your request, we will reply you within 24 working hours!</Alert> : ""}
                            {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}

                            <Form onSubmit={this.sendMsg}>
                                <Form.Group className="mb-20" controlId="formBasicEmail">
                                    <Form.Label>Topic of Message</Form.Label>
                                    <Form.Control type="text" name="topic" required={true} value={this.state.topic} onChange={this.handleChange}/>
                                </Form.Group>

                                <Form.Group className="mb-20" controlId="formBasicPassword">
                                    <Form.Label>Your Message</Form.Label>
                                    <Form.Control as="textarea" rows="4" name="message" required={true} value={this.state.message} onChange={this.handleChange}/>
                                </Form.Group>

                                <Button variant="primary" type="submit" disabled={this.state.disabled}>
                                    Send
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Collaborate;