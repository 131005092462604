import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card, Alert} from 'react-bootstrap';
import { IoBagCheckOutline } from 'react-icons/io5';
import {GrMoney} from 'react-icons/gr';
import {RiBarChart2Line} from 'react-icons/ri';
import {MdOutlineMailOutline, MdOutlineRestartAlt} from 'react-icons/md';
import config from '../../config'
const TP_API_URL = config.TP_API_URL;


var dateFormat = require("dateformat");
let source = axios.CancelToken.source();

const columns = [
    {
        name: 'Name',
        selector: 'cart_name',
        sortable: false,
        right: false,
        cell: row => <div data-tag="allowRowEvents"><div data-tag="allowRowEvents" style={{ fontWeight: 700 }}>{row.cart_name}</div></div>,
    },
    {
        name: 'Phone Number',
        selector: 'sms_to',
        sortable: false,
        right: false,
        cell: row => <div data-tag="allowRowEvents">{row.sms_to}</div>,
    },
    {
        name: 'Last Contacted',
        selector: 'updated_on',
        sortable: false,
        right: false,
        cell: row =><div data-tag="allowRowEvents">{row.updated_on ? dateFormat(row.updated_on) : ""}</div>,
    },
    {
        name: 'Total Messages',
        selector: 'sms_total',
        sortable: false,
        right: false
    },

    {
        name: 'Status',
        selector: 'cart_status',
        sortable: false,
        right: false,
        cell: row =><div data-tag="allowRowEvents"><span className={'cart-status ' + row.cart_status}>{row.cart_status}</span></div>,
    }
];

class Dashboard extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        loading:true,
        loading_text:"Loading...",
        data:[{}],
        conversations:[{}],
        uninstalled:0,
        uninstalled_on:"",
        shop_name:""
    };

    getData = () => {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");

        if (source) { source.cancel();}
        source = axios.CancelToken.source();
        axios.get(TP_API_URL+"client/dashboard", {
            params:{shop_id:tp_shop},
            headers:{token: tp_access_token},
            cancelToken: source.token
        }).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                this.setState({
                    loading:false,
                    data:response.data,
                    conversations:response.conversations,
                    uninstalled:response.uninstalled,
                    uninstalled_on:response.uninstalled_on,
                    shop_name:response.shop_name
                });
            } else {
                this.setState({
                    loading_text:response.error_msg
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
            this.setState({
                loading_text:e.message
            });
        });
    }

    componentDidMount(){
        this.getData();
    }

    componentWillUnmount() {
        if(source) { source.cancel();}
    }

    routeChange=(data)=> {
        this.props.history.push("/dashboard/conversation/view/"+data.id);
    }


    render () {

        return (
            this.state.loading ? <div className='loading2'><p>{this.state.loading_text}</p></div> :
                <> 
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <h3 className="page-heading sidebar-btn">
                                    Dashboard
                                </h3>

                            </Col>
                        </Row>
                    </Container>

                    <Container fluid>
                        <Row>
                            <Col sm={12} className="mb-20">
                                {this.state.uninstalled===1 ? 
                                <Alert variant="danger">
                                    <Alert.Heading>APP DELETED</Alert.Heading>
                                    <p>
                                        Cart Rescue has been deleted from <b>{this.state.shop_name}</b> on <b>{dateFormat(this.state.uninstalled_on, "mm-dd-yyyy")}</b> <br/>
                                        Your Cart Rescue data will be permanently deleted after 30 days from {dateFormat(this.state.uninstalled_on, "mm-dd-yyyy")}, You can re-install app to continue using Cart Rescue.
                                    </p>
                                </Alert>
                                : "" }
                                {/*
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Setup Instructions</Accordion.Header>
                                        <Accordion.Body>
                                            <ol>
                                                <li><b>Create a SMS discount</b> <br /> Select the discount amount we'll offer your customers over text to recover their abandoned cart. We recommend choosing a discount that is slightly higher than what you usually offer. For example, if you give 10% off for subscribing to your newsletter, create a 15% discount instead. The discount code will be automatically generated by us and will start with "TP"</li>
                                                <li><b>Provide us with customer service information</b> <br /> Please give us the customer service email and phone number you would like us to send to your customers in the rare instance that we're not able to resolve their issue.</li>
                                                <li><b>Provide us with basic shop page information</b> <br/> Please select the page of your store that best fits the category. This will help our team look through your website to answer the questions of your customers.</li>
                                                <li>
                                                    <b>Configure your checkout</b>
                                                    <ul>
                                                        <li>From your Shopify Admin, go to Settings &gt; Checkout</li>
                                                        <li>Scroll down to Customer contact, and change "To check out" to "Customers can only check out using email."</li>
                                                        <li>Next, scroll down until you see Form options, and then change "Shipping address phone number to Required. Save changes.</li>
                                                        <li>Change opt in sign up box on checkout to say “Keep me up to date on news and exclusive offers via email and sms”
                                                            <ul>
                                                                <li>Click Manage checkout language</li>
                                                                <li>Search for "marketing" and update the text under "Accept marketing checkbox label" to say “Keep me up to date on news and exclusive offers via email and sms”</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><b>Ready to go!</b> <br /> We will get a phone number for your store and begin texting your customers. Please email us at Blake@cartrescue.io if you have any questions.</li>
                                            </ol>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                */}
                            </Col>
                        </Row>
                    </Container>

                    <Container fluid >
                        <Row className="gx-2">
                        <Col className='mb-20'>
                                <Card body className="statistics">
                                    <Row>
                                        <Col xs={12} className="sicon_wrapper">
                                            <GrMoney className="sicon" />
                                        </Col>
                                        <Col xs={12}>
                                            <p>Total Sales</p>
                                            <h5>${this.state.data.total_sales}</h5>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='mb-20'>
                                <Card body className="statistics">
                                    <Row>
                                        <Col xs={12} className="sicon_wrapper">
                                            <RiBarChart2Line className="sicon" />
                                        </Col>
                                        <Col xs={12}>
                                            <p>Average Order Value</p>
                                            <h5>${this.state.data.avg_order_value}</h5>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='mb-20'>
                                <Card body className="statistics">
                                    <Row>
                                        <Col xs={12} className="sicon_wrapper">
                                            <MdOutlineRestartAlt className="sicon" />
                                        </Col>
                                        <Col xs={12}>
                                            <p>Recovery Rate</p>
                                            <h5>{this.state.data.recovery_rate}%</h5>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='mb-20'>
                                <Card body className="statistics">
                                    <Row>
                                        <Col xs={12} className="sicon_wrapper">
                                            <MdOutlineMailOutline className="sicon" />
                                        </Col>
                                        <Col xs={12}>
                                            <p>Conversations</p>
                                            <h5>{this.state.data.conversations}</h5>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='mb-20'>
                                <Card body className="statistics">
                                    <Row>
                                        <Col xs={12} className="sicon_wrapper" >
                                            <IoBagCheckOutline className="sicon" />
                                        </Col>
                                        <Col xs={12}>
                                            <p>Orders Recovered</p>
                                            <h5>{this.state.data.orders_recovered}</h5>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            
                            
                        </Row>
                    </Container>

                    <Container fluid>
                        <Row>
                            <Col sm={12} className='billing'>
                                <h3 className="table-heading">
                                    Latest Conversations
                                </h3>
                                <Card body>
                                    <DataTable
                                        noHeader={true}
                                        columns={columns}
                                        data={this.state.conversations}
                                        pagination={false}
                                        paginationServer={false}
                                        pointerOnHover={true}
                                        onRowClicked={this.routeChange}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </>
            
        );
    }
}

export default withRouter(Dashboard);