import React, { useEffect, useState } from 'react';
import { Dropdown, Row, Col, DropdownButton, Form} from 'react-bootstrap';
import { availableConditions, statesCA, statesUS } from '../helper/Conditions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { TP_API_URL } from '../config';

const ConditionsComponent = ({ conditions, setConditions }) => {
    const [rules, setRules] = useState(availableConditions);
    
    const addCondition = () => {
        for (const key in availableConditions) {
            const condition = availableConditions[key];
            const availableValues = (rules[key]?.values || []).filter(val => !conditions.some(c => c.value === val));
            const newValue = availableValues.length > 0 ? availableValues[0] : null; 

            if (newValue) {
                setConditions([...conditions, { field: key, operator: condition.operators[0], value: newValue, connector: 'OR' }]);
                break;
            }
        }
    };
    
    const updateCondition = (index, part, value) => {
        let updatedConditions = conditions.map((condition, idx) => 
            idx === index ? { ...condition, [part]: value } : condition
        );
    
        // If 'field' part changes, reset 'operator' and 'value' to first available options
        if (part === 'field') {
            const newFieldOperators = availableConditions[value]?.operators || [];
            const newFieldValues = value === 'state' ?
                (value === 'United States' ? statesUS : (value === 'Canada' ? statesCA : [])) : 
                availableConditions[value]?.values || [];
            const firstOperator = newFieldOperators.length > 0 ? newFieldOperators[0] : '';
            const firstValue = newFieldValues.length > 0 ? newFieldValues[0] : '';
    
            updatedConditions = updatedConditions.map((condition, idx) =>
                idx === index ? { ...condition, operator: firstOperator, value: firstValue } : condition
            );
        } else if (part === 'operator') {
            // If 'operator' part changes, reset 'value' to first available option
            const currentValue = conditions[index]?.field;
            const newFieldValues = currentValue === 'state' ?
                (currentValue === 'United States' ? statesUS : (currentValue === 'Canada' ? statesCA : [])) : 
                availableConditions[currentValue]?.values || [];
            const firstValue = newFieldValues.length > 0 ? newFieldValues[0] : '';
    
            updatedConditions = updatedConditions.map((condition, idx) =>
                idx === index ? { ...condition, value: firstValue } : condition
            );
        }
    
        setConditions(updatedConditions);
    };
    
    const removeCondition = (index) => {
      setConditions(conditions.filter((_, idx) => idx !== index));
    };

    const fetchProducts = async () => {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
    
        try {
            const response = await axios.get(`${TP_API_URL}client/segments/options/products`, {
                headers: { token: tp_access_token },
                params: { shop_id: tp_shop },
            });
    
            const responseData = response.data;
    
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if (responseData.error_code===0) {
                const updateAvailableConditions = (existingConditions, productValues) => {
                    const updatedConditions = {
                      ...existingConditions,
                      product: {
                        ...existingConditions.product,
                        values: productValues.map(product => product.title)
                      }
                    };
                  
                    return updatedConditions;
                };
                  
                const newRules = updateAvailableConditions(availableConditions, responseData.products);
                setRules(newRules);
            } else {
                console.log('Error getting products.');
            }
        } catch (error) {
            console.log('Error getting products.');
        }
    };

    useEffect(() => {
        fetchProducts();
    });

    return (
        <div>
            {conditions.map((condition, index) => (
                <Row className="mb-5" key={index}>
                    <Col xs={4}>
                        <DropdownButton 
                            id={`dropdown-basic-button-condition-${index}`}
                            title={condition.field || 'Select Condition'}
                            className="custom-dropdown figtree"
                            onSelect={(eventKey) => updateCondition(index, 'field', eventKey)}
                        >
                            {Object.keys(rules).map((key) => (
                                <Dropdown.Item
                                    key={key}
                                    eventKey={key}
                                >
                                    {key}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </Col>
                    <Col xs={2}>
                        <DropdownButton 
                            id={`dropdown-basic-button-operator-${index}`}
                            title={condition.operator || 'Select Operator'} 
                            className="custom-dropdown figtree"
                            onSelect={(eventKey) => updateCondition(index, 'operator', eventKey)}
                        >
                            {(rules[condition.field]?.operators || []).map((op) => (
                                <Dropdown.Item
                                    key={op}
                                    eventKey={op}
                                >
                                    {op}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </Col>
                    <Col xs={5}>
                        <DropdownButton 
                            id={`dropdown-basic-button-value-${index}`}
                            title={condition.value || 'Select Value'} 
                            className="custom-dropdown figtree"
                            onSelect={(eventKey) => updateCondition(index, 'value', eventKey)}
                        >
                            {(rules[condition.field]?.values || []).filter(val => !conditions.some(c => c.value === val)).map((val) => (
                                <Dropdown.Item
                                    key={val}
                                    eventKey={val}
                                >
                                    {val}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </Col>
                    <Col xs={1}>
                        {conditions.length > 1 && (
                            <button onClick={() => removeCondition(index)} className='height-100-percent custom-times '>
                               <FontAwesomeIcon icon={faTimes} className="  menuicon mr-5" />
                            </button>
                        )}
                    </Col>
                    <Form.Text className="text-muted"> <FontAwesomeIcon icon={faInfoCircle} className="figtree menuicon mr-5" />based on orders associated with this customer</Form.Text>
                    {(conditions.length > 1 && index !== conditions.length - 1) && (
                        <div className='condition-flex'>
                            <div className='line'></div>
                            <DropdownButton 
                                id={`dropdown-basic-button-connector-${index}`}
                                title={condition.connector || 'Select Connector'} 
                                className="custom-dropdown figtree"
                                onSelect={(eventKey) => updateCondition(index, 'connector', eventKey)}
                            >
                                 <Dropdown.Item
                                    key={'OR'}
                                    eventKey='OR'
                                >
                                    OR
                                </Dropdown.Item>
                                <Dropdown.Item
                                    key={'AND'}
                                    eventKey='AND'
                                >
                                    AND
                                </Dropdown.Item>
                            </DropdownButton>
                            <div className='line'></div>
                        </div>
                    )}
                </Row>
            ))}
            <button onClick={addCondition} className='mt-22 custom-white'><FontAwesomeIcon icon={faPlus} className="menuicon mr-5 figtree" /> Add Rule</button>
        </div>
    );
};

export default ConditionsComponent;
