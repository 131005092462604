import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { TP_API_URL } from '../../config';
import { SegmentForm } from '../../components/SegmentForm';

const initialCondition = {
    field: 'customer type',
    operator: 'is',
    value: 'first time customer',
    connector: 'OR'
};

class SegmentsEdit extends Component {
    
    state = {
        loading: true,
        loading_text: "Loading...",
        segment: {
            id: null,
            name: "",
            action: "offer_discount",
            discount_type: "percentage",
            discount_value: 30,
            conditions: [initialCondition],
        }
    };

    componentDidMount() {
        this.setState({ loading: false });
        this.getSegment(this.props.match.params.id);
    }

    goBack = () => {
        this.props.history.goBack();
    }
    
    getSegment= async (id)=> {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
    
        try {
            const response = await axios.get(`${TP_API_URL}client/segments/${id}`, {
                headers: { token: tp_access_token },
                params: { shop_id: tp_shop },
            });
    
            const responseData = response.data;
    
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if (responseData.error_code===0) {
                console.log('responseData', responseData)
                this.setState({
                    segment: responseData.segment,
                    loading_text: 'Segment loaded successfully.'
                })
            } else {
                this.setState({
                    loading_text: 'Error getting segment.'
                });
            }
        } catch (error) {
            this.setState({
                loading_text: error.message
            });
        }
    }

    updateSegment= async ()=> {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        const id = this.props.match.params.id;
    
        try {
            const response = await axios.patch(`${TP_API_URL}client/segments/${id}`, {
                name: this.state.segment.name,
                action: this.state.segment.action,
                discount_type: this.state.segment.discount_type,
                discount_value:  Number(this.state.segment.discount_value),
                rules: this.state.segment.rules
            }, {
                headers: { token: tp_access_token },
                params: { shop_id: tp_shop },
            });
    
        const responseData = response.data;
    
        if(response.error_code===401){
            this.props.history.push("/login");
        } else if (responseData.success === 1) {
            this.setState({
                loading_text: 'Segment updated successfully.'
            });
            setTimeout(() => {
                this.setState({
                    loading_text: 'Redirecting to segments page...'
                });
            }, 100);
            setTimeout(() => {
                this.props.history.push('/dashboard/segments');
            }, 100);
        } else {
            this.setState({
                loading_text: 'Error updating segment.'
            });
        }
        } catch (error) {
        this.setState({
            loading_text: error.message
        });
        }
    }

    deleteSegment= async ()=> {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        const id = this.props.match.params.id;
    
        try {
        const response = await axios.delete(`${TP_API_URL}client/segments/${id}`,{
            headers: { token: tp_access_token },
            params: { shop_id: tp_shop },
        });
    
        const responseData = response.data;
    
        if(response.error_code===401){
            this.props.history.push("/login");
        } else if (responseData.success) {
            this.setState({
                loading_text: 'Segment deleted successfully.'
            });
            setTimeout(() => {
                this.setState({
                    loading_text: 'Redirecting to segments page...'
                });
            }, 100);
            setTimeout(() => {
                this.props.history.push('/dashboard/segments');
            }, 100);
        } else {
            this.setState({
                loading_text: 'Error deleting segment.'
            });
        }
        } catch (error) {
        this.setState({
            loading_text: error.message
        });
        }
    }

    setSegment = (updatedSegment) => {
        this.setState({ segment: updatedSegment });
    };

    render() {
        const { loading, loading_text } = this.state;

        return loading ? (
            <div className='loading2'><p>{loading_text}</p></div>
        ) : (
            <SegmentForm
                isEditing={true} 
                segment={this.state.segment} 
                setSegment={this.setSegment}
                onUpdate={this.updateSegment} 
                onDelete={this.deleteSegment}
                onBack={this.goBack}
          />
        );
    }
}

export default withRouter(SegmentsEdit);
