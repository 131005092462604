import React, { Component} from 'react';
import axios from 'axios';
import { NavLink, withRouter } from 'react-router-dom';
import { Container, Col, Row, Card, Form} from 'react-bootstrap';
import Logo from '../../assets/logo.svg'
import config from '../../config'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faShippingFast} from '@fortawesome/free-solid-svg-icons'
import {TbDiscount2} from 'react-icons/tb'

const TP_API_URL = config.TP_API_URL;


class OBDiscount extends Component {
    state = {
        loading:false,
        error_code:0,
        error_msg:"",
        shop:{},
        discount_value: 15,
        discount_type:1,
        rangeLeft:22.2222,
        lp:4.66667
    };

    Verify_shop = () => {
        this.setState({
            error_code:0,
            error_msg:"",
            loading:true
        });

        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");

        const ax_headers = {
            params:{
                shop_id : tp_shop
            },
            headers: {
                token: tp_access_token
            }
        }

        axios.get(TP_API_URL+"client/shop/view", ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState(
                    {
                        loading:false,
                        shop : response.data,
                        discount_type:response.data.discount_type
                    }
                );

                if(response.data.discount_value>0) {
                    this.calMargin(response.data.discount_value);
                    this.setState(
                        {
                            discount_value : response.data.discount_value
                        }
                    );
                }
            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    calMargin = (target) => {
        const val = target;
        const min = 5;
        const max = 50;
        const rangeLeft = Number(((val - min) * 100) / (max - min));
        const lp = 8 - rangeLeft*0.15;
        this.setState({
            rangeLeft: rangeLeft,
            lp : lp
        });
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

        if(target.type === 'range') {
            const val = target.value;
            const min = target.min ? target.min : 0;
            const max = target.max ? target.max : 100;
            const rangeLeft = Number(((val - min) * 100) / (max - min));
            const lp = 8 - rangeLeft*0.15;
            this.setState({
                rangeLeft: rangeLeft,
                lp : lp
            });
        }
    }

    select_offer = (id) => {
        this.setState(
            {
                discount_type:id
            }
        );
    }

    Update_shop = () => {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        
        const ax_headers = {
            headers: {
                token: tp_access_token
            }
        }

        axios.post(TP_API_URL+"client/shop/update", {
            shop_id : tp_shop,
            discount_value:this.state.discount_value,
            discount_type:this.state.discount_type
        }, ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState(
                    {
                        loading:false
                    }
                );
            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    componentWillMount(){
        this.Verify_shop();
    }


    render() {
        return(
            <>
                {this.state.loading ? <div className="loading">Loading...</div> :
                <Container fluid className="bg-light OBContainer">
                    <Row className="mvh-90 align-items-center justify-content-center ob-ohidden">

                        <Col xl={4} lg={5} md={8}>
                            <div className='text-center mt-40'><img src={Logo} alt="Cart Rescue" className="mb-30 h-40" /></div>
                            <Card className="card-ob">
                                <div className="ob-circle-1"></div>
                                <div className="ob-circle-2"></div>
                                <div className="ob-circle-3"></div>
                                <Card.Body>

                                    <Card.Title>Choose Offer</Card.Title>
                                    <ul className='offer-options mb-30'>
                                        <li className={this.state.discount_type===1 ? "active" : ""} onClick={() => this.select_offer(1)}>
                                            <TbDiscount2/>
                                            <span>Discount Code</span>
                                        </li>
                                        <li className={this.state.discount_type===2 ? "active" : ""} onClick={() => this.select_offer(2)}>
                                            <FontAwesomeIcon icon={faShippingFast} />
                                            <span>Free Shipping</span>
                                        </li>
                                    </ul>
                                    {this.state.discount_type===1 ? 
                                    <>
                                        <h5 className='text-center mb-30'>What do you want the discount to be?</h5>
                                        <div className='rangeBubbleOutter'><span className="rangeBubble" style={{left:"calc("+this.state.rangeLeft+"% + "+this.state.lp+"px)"}}>{this.state.discount_value}% Off</span></div>
                                        <Form.Range className='mb-20 ob-range' variant="success" defaultValue={this.state.discount_value} min="5" max="50" name="discount_value" onChange={this.handleChange} />
                                    </>
                                    : "" }
                                    <p className='text-center'>Sample message</p>

                                    <div className="text-light text-center wc-4 mb-30">
                                        <div className="wc-4-inner">
                                            <span></span>
                                            <div className="wc-4-msgbox">
                                                {this.state.discount_type===2 ? 
                                                <>I just made you a code for <b>Free Shipping</b>. It's CR-XXXX. Just make sure to apply it at checkout to get free shipping 😊</>
                                                : <>I just made you a code for <b>{this.state.discount_value}% off</b>. It's CR-XXXX. Just make sure to apply it at checkout to get your discount 😊</>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='d-grid gap-2 wc-btn'>
                                        <NavLink to="/onboarding/post-purchase" className="btn btn-ob btn-ob-success btn-success" onClick={this.Update_shop}  >
                                            Continue
                                        </NavLink>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                }
            </>
        );
    }

}

export default withRouter(OBDiscount);