import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row, Card } from 'react-bootstrap';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import config from '../../config'
import Abandoned from "../../components/charts/Abandoned";
import { HiOutlineCreditCard } from 'react-icons/hi';
import { IoBagOutline } from 'react-icons/io5';
import { RiHandCoinLine } from 'react-icons/ri';

const TP_API_URL = config.TP_API_URL;
var dateFormat = require("dateformat");
let source = axios.CancelToken.source();

const columns = [
    {
        name: 'Date',
        selector: 'order_created_on',
        sortable: true,
        left: true,
        cell: row =><div data-tag="allowRowEvents">{row.order_created_on ? dateFormat(row.order_created_on) : ""}</div>,
    },
    {
        name: 'Order Name',
        selector: 'order_name',
        sortable: false,
        right: false,
        cell: row => <div>{row.order_name}</div>
    },
    {
        name: 'Order Id',
        selector: 'order_id',
        sortable: false,
        right: false,
        cell: row => <div>{row.order_id}</div>
    },
    {
        name: 'Order Amount',
        selector: 'order_total',
        sortable: false,
        right: false,
        cell: row => <div>${row.order_total}</div>
    },
    {
        name: 'Charge Id',
        selector: 'charge_id',
        sortable: false,
        right: false,
        cell: row =><div data-tag="allowRowEvents">{row.charge_id}</div>
    },
    {
        name: 'Charged Amount',
        selector: 'charged_amount',
        sortable: false,
        right: false,
        cell: row =><div data-tag="allowRowEvents">${row.charged_amount} ({((row.charged_amount/row.order_total)*100).toFixed(1)}%)</div>
    }
];


class Billing extends Component {
    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        loading:true,
        loading_text:"Loading...",
        tableloading:false,
        data:[{}],
        invoices:[{}],
        total:0,
        page:1,
        shop:[],
        start: moment().subtract(9, 'days'),
        end: moment(),
        data_abd:{
            total1: 0,
            total2: 0,
            shop_plan: 0,
            shop_per: 0
        },
        charges:[]
    };

    setPage = (page) => {
        this.setState({page:page});
    }

    getData = () => {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        this.setState({tableloading:true});
        if (source) { source.cancel();}
        source = axios.CancelToken.source();
        axios.get(TP_API_URL+"client/invoices/list", {
            params:{shop_id:tp_shop, page:this.state.page},
            headers:{token: tp_access_token},
            cancelToken: source.token
        }).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                this.setState({
                    loading:false,
                    tableloading:false,
                    data:response.data,
                    invoices:response.invoices,
                    shop:response.shop
                });
            } else {
                this.setState({
                    loading_text:response.error_msg
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
            this.setState({
                loading_text:e.message
            });
        });
    }


    handleCallback = (start, end) => {
        this.setState({
            start: start,
            end: end
        });
    };

    dataCust = (v1, v2, v3, v4, v5) => {
        this.setState({
            data_abd: {
                total1: v1,
                total2: v2,
                shop_plan: v3,
                shop_per: v4
            },
            charges:v5,
            loading:false
        });

    }


    componentDidMount(){
        let upage = parseInt(this.props.match.params.page);
        if(!upage){ upage=1;}
        this.setState({page:upage});
        //this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevState.page!==this.state.page){
            this.getData();
        }
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }

    render(){
        const tp_shop = localStorage.getItem("tp_shop");
        const label = this.state.start.format('MMMM D, YYYY') + ' - ' + this.state.end.format('MMMM D, YYYY');
        return (
            
            <>
                
                <Container fluid={true}>
                    <Row>
                        <Col sm={8} xs={3}>
                            <h3 className="page-heading sidebar-btn">
                                Billing
                            </h3>
                        </Col>
                        <Col sm={4} xs={9}>
                            <DateRangePicker
                                initialSettings={{
                                startDate: this.state.start.toDate(),
                                endDate: this.state.end.toDate(),
                                ranges: {
                                    'Last 7 Days': [
                                    moment().subtract(6, 'days').toDate(),
                                    moment().toDate(),
                                    ],
                                    'Last 10 Days': [
                                        moment().subtract(9, 'days').toDate(),
                                        moment().toDate(),
                                    ],
                                    'Last 30 Days': [
                                    moment().subtract(29, 'days').toDate(),
                                    moment().toDate(),
                                    ],
                                    'This Month': [
                                    moment().startOf('month').toDate(),
                                    moment().endOf('month').toDate(),
                                    ],
                                    'Last Month': [
                                    moment().subtract(1, 'month').startOf('month').toDate(),
                                    moment().subtract(1, 'month').endOf('month').toDate(),
                                    ],
                                },
                                }}
                                onCallback={this.handleCallback}
                            >
                                <div
                                id="reportrange"
                                className="form-control-sm form-control mt-23"
                                style={{
                                    background: '#fff',
                                    cursor: 'pointer',
                                    padding: '5px 10px',
                                    border: '1px solid #ccc',
                                    width: 'auto',
                                    display:'inline-block',
                                    float:'right'
                                }}
                                >
                                <i className="fa fa-calendar"></i>&nbsp;
                                <span>{label}</span> <i className="fa fa-caret-down"></i>
                                <input type="hidden" id="startdate" value={this.state.start.format('YYYY-MM-DD')} />
                                <input type="hidden" id="enddate" value={this.state.end.format('YYYY-MM-DD')} />
                                </div>
                            </DateRangePicker>
                        </Col>
                    </Row>
                </Container>
                
                 
                <Container fluid>
                    <Row className="gx-2">
                        <Col className='mb-20'>
                            <Card body className="statistics">
                                <Row>
                                    <Col xl={12} className="sicon_wrapper">
                                        <HiOutlineCreditCard className="sicon" />
                                    </Col>
                                    <Col xl={12}>
                                        <p>{this.state.data_abd.shop_plan ? "" : "Loading..."}{this.state.data_abd.shop_plan===1 ? "STARTER (Free)" : ""} {this.state.data_abd.shop_plan===2 ? "GROWTH ($49/month)" : ""} {this.state.data_abd.shop_plan===3 ? "PRO ($299/month)" : ""} {this.state.data_abd.shop_plan===4 ? "ENTERPRISE ($999/month)" : ""}</p>
                                        <h5>{this.state.data_abd.shop_per}%</h5>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col className='mb-20'>
                            <Card body className="statistics">
                                <Row>
                                    <Col xl={12} className="sicon_wrapper">
                                        <IoBagOutline className="sicon" />
                                    </Col>
                                    <Col xl={12}>
                                        <p>Total amount recovered</p>
                                        <h5>${this.state.data_abd.total1}</h5>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col className='mb-20'>
                            <Card body className="statistics">
                                <Row>
                                    <Col xl={12} className="sicon_wrapper" >
                                        <RiHandCoinLine className="sicon" />
                                    </Col>
                                    <Col xl={12}>
                                        <p>Commissions</p>  
                                        <h5>${this.state.data_abd.total2}</h5>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="gx-2">
                        <Col>
                            <Abandoned start={this.state.start.format('YYYY-MM-DD')} end={this.state.end.format('YYYY-MM-DD')} shop={tp_shop} onCallback={this.dataCust} ></Abandoned>

                        </Col>
                    </Row>
                </Container>
                
                <Container fluid className='mt-20'>
                    <Row>
                        <Col className='billing'>
                            <Card body>
                                <DataTable
                                    title="Charges"
                                    columns={columns}
                                    data={this.state.charges}
                                    highlightOnHover={false}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withRouter(Billing);