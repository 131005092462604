import React, { Component} from 'react';
import axios from 'axios';
import { NavLink, withRouter } from 'react-router-dom';
import { Container, Col, Row, Card, Alert} from 'react-bootstrap';
import Logo from '../../assets/logo.svg'
import config from '../../config'

const TP_API_URL = config.TP_API_URL;


class OBWelcome extends Component {
    state = {
        loading:false,
        error_code:0,
        error_msg:"",
        shop:{}
    };

    Verify_shop = () => {

        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");

        const ax_headers = {
            params:{
                shop_id : tp_shop
            },
            headers: {
                token: tp_access_token
            }
        }

        axios.get(TP_API_URL+"client/shop/view", ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState(
                    {
                        loading:false,
                        shop : response.data
                    }
                );
            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    Update_shop = () => {

        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");

        const ax_headers = {
            headers: {
                token: tp_access_token
            }
        }

        axios.post(TP_API_URL+"client/shop/update", {
            shop_id : tp_shop,
            onboarding: 1
        }, ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState(
                    {
                        loading:false
                    }
                );
            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    componentWillMount(){
        this.Verify_shop();
        this.setState({
            error_code:0,
            error_msg:""
        });
    }

    render() {
        return(
            <>
                {this.state.loading ? <div className="loading">Loading...</div> :
                <Container fluid className="bg-light OBContainer">
                    <Row className="mvh-90 align-items-center justify-content-center ob-ohidden">

                        <Col xl={4} lg={5} md={8}>
                            <div className='text-center mt-40'><img src={Logo} alt="Cart Rescue" className="mb-30 h-40" /></div>
                            <Card className="card-ob">
                                <div className="ob-circle-1"></div>
                                <div className="ob-circle-2"></div>
                                <div className="ob-circle-3"></div>
                                <Card.Body>
                                    {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}
                                    <h3>Hi &amp; welcome!</h3>
                                    <p className="wc-1"><b>Cart Rescue</b> gets you closer to your customers, helping you drive the business results you need.</p>

                                    <p className="wc-2"><b>Here's how it works:</b> Our live agents will reach out to your customers over text within 24 hours of them abandoning their checkout.</p>

                                    <p className="wc-3">Our initial text message is pre-configured for the highest response and open rate</p>
                                    
                                    <p className="wc-icon">
                                        <svg width="25" height="50" viewBox="0 0 25 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.334 40.8694C16.0624 40.2671 17.7909 39.6219 19.5625 39.0842C20.3618 38.8262 21.226 38.6326 22.0685 38.6326C23.2136 38.6326 24.2507 39.6865 24.4019 40.8049C24.51 41.6007 23.8834 42.8481 23.1704 42.8051C20.3618 42.6545 18.3309 44.4397 16.0192 45.4721C14.0531 46.3539 12.2384 47.5584 10.2723 48.4402C8.84641 49.0854 7.33402 49.6876 5.80007 49.9242C2.14883 50.4834 -0.551767 47.881 0.0963816 44.3322C1.00379 39.4068 2.14885 34.5245 4.0933 29.8573C4.48219 28.954 4.97912 28.0507 5.62727 27.3194C6.6211 26.201 8.80313 26.8247 9.01918 28.3088C9.19202 29.5562 9.06245 30.8682 8.97604 32.1587C8.84641 34.1374 8.60873 36.1161 8.41429 38.2669C9.08404 38.2454 9.58095 38.3744 9.81861 38.1809C13.7723 34.7611 17.0995 30.8252 19.3032 26.072C21.1612 22.093 21.9606 17.9419 20.7507 13.5543C19.0439 7.38155 14.1612 3.29506 7.72289 2.8649C7.00992 2.82189 6.31858 2.8649 5.80007 2.8649C4.93587 1.91856 5.30317 1.25182 5.92972 0.886187C7.33404 0.0688864 8.86793 -0.146182 10.5099 0.0904048C18.1581 1.27334 24.0131 7.4461 24.8557 15.1889C25.331 19.7056 24.6396 24.0287 22.6735 28.1582C21.0532 31.5565 18.9143 34.5891 16.4945 37.4496C15.6735 38.439 14.8093 39.3853 13.9667 40.3532C14.0747 40.5037 14.2044 40.6758 14.334 40.8694Z" fill="#14223D"/>
                                        </svg>
                                    </p>

                                    <div className="text-light text-center wc-4">
                                        <div className="wc-4-inner">
                                            <span></span>
                                            <div className="wc-4-msgbox">
                                                Hey John, this is Amber from  <b>{this.state.shop.shop_name}</b>. I saw you were checking out our Backpack in Black. Great choice! Do you want me to see if I could get you a discount?
                                                <svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 17.8017C10.1236 18.8273 16.917 6.87932 12.9047 0C13.592 0.738087 27.0631 9.88845 31 14C23.1859 9.606 18.5599 22.6475 0 17.8017Z" fill="white"/>
                                                </svg>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <p className="wc-5">Note: if the product name doesn't look fit for text message, don't worry. We'll edit it before it gets sent off to your customer.</p>

                                    <div className="d-grid gap-2 wc-btn">
                                        <NavLink to="/onboarding/discount" className="btn btn-ob btn-ob-success btn-success" onClick={this.Update_shop} >
                                            Continue
                                        </NavLink>
                                    </div>
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                }
            </>
        );
    }

}

export default withRouter(OBWelcome);