import React, { Component} from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Logo from '../../assets/logo.svg';
import config from '../../config'

const TP_API_URL = config.TP_API_URL;

class Authorize extends Component {


    state = {
        loading:true,
        error_code:"Loading",
        error_msg:"verifying your request",
        shop_data:[],
        user_data:[]
    };

    verify_token = () => {
        const token = this.props.match.params.token;
        //set token to localstorage.
        localStorage.setItem('tp_shop_token', token);

        const ax_headers = {
            headers: {
                token: token
            }
        }

        if(token){
            axios.post(TP_API_URL+"client/shop/authorize", "", ax_headers)
            .then((res) => {
                const response = res.data;
                if(response.error_code===0){
                    const tp_access_token = localStorage.getItem('tp_access_token');
                    if(response.data.shop_user_id>0 && response.user_id_data.id>0){
                        //save token and redirect to dashboard or onboarding
                        localStorage.removeItem('tp_shop_token');
                        localStorage.setItem('tp_access_token', response.user_id_data.token);
                        localStorage.setItem('tp_shop', response.user_id_data.default_shop_id);
                        if(response.data.onboarding===0){
                            this.props.history.push("/onboarding/welcome");
                        } else if(response.data.onboarding===1){
                            this.props.history.push("/onboarding/discount");
                        } else if(response.data.onboarding===2){
                            this.props.history.push("/onboarding/contact-info");
                        } else if(response.data.onboarding===3){
                            this.props.history.push("/onboarding/pages");
                        } else if(response.data.onboarding===4){
                            this.props.history.push("/onboarding/complete");
                        } else {
                            this.props.history.push("/dashboard");
                        }
                    } else if (response.user_email_data.id>0 || tp_access_token) {
                        this.props.history.push("/onboarding/login");
                    } else {
                        this.props.history.push("/onboarding/register");
                    }
                }

                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg
                    }
                );
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
        }
    }

    componentWillMount(){
        this.verify_token();
    }


    render() {
        return(
            <>
                <Container fluid className="bg-light py-5vh">
                    <Row className="mvh-90 align-items-center justify-content-center">
                        <Col xl={4} lg={5} md={8}>
                            <Card>
                                <Card.Body className="p-50">
                                    <img src={Logo} alt="Cart Rescue" className="mb-30 h-40" />
                                    <Card.Title>{this.state.error_code}</Card.Title>
                                    <Card.Subtitle className="text-secondary">{this.state.error_msg}</Card.Subtitle>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withRouter(Authorize);