import React, { Component} from 'react';
import axios from 'axios';
import { NavLink, withRouter } from 'react-router-dom';
import { Container, Col, Row, Button, Card, Form, Alert} from 'react-bootstrap';
import Logo from '../../assets/logo.svg'
import {IoPersonOutline} from 'react-icons/io5';
import config from '../../config'
const TP_API_URL = config.TP_API_URL;

class Forgot extends Component {
    
    state = {
        loading:false,
        disabled:false,
        layout:1,
        user : {},
        error_code:0,
        error_msg:"",
        name:"",
        email:"",
        password:"",
        cpassword:"",
        code:""
    };

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    Code = (event) => {
        event.preventDefault();
        this.setState({
            error_code:0,
            error_msg:"",
            disabled:true
        });

        axios.post(TP_API_URL+'/client/users/code', { 
            email:this.state.email
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState({
                    error_code:0,
                    error_msg:"",
                    layout:2,
                    name:response.data.name,
                    email:response.data.email,
                    disabled:false
                });
            } else {
                this.setState({
                    error_code:response.error_code,
                    error_msg:response.error_msg,
                    disabled:false
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    UpdatePassword = (event) => {
        event.preventDefault();
        this.setState({
            error_code:0,
            error_msg:"",
            disabled:true
        });

        if(this.state.password!==this.state.cpassword){
            this.setState({
                error_code:1,
                error_msg:"Your password does not match",
                disabled:false
            });

        } else {

            axios.post(TP_API_URL+'/client/users/change-password', { 
                code:this.state.code,
                email:this.state.email,
                password:this.state.password
            })
            .then((res) => {
                const response = res.data;
                if(response.error_code===0){
                    localStorage.setItem('tp_access_token', response.data.token);
                    this.props.history.push("/login");
                } else {
                    this.setState({
                        error_code:response.error_code,
                        error_msg:response.error_msg,
                        disabled:false
                    });
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
        }
    }


    render() {
        return(
            <>
                {this.state.loading ? <div className="loading">Loading...</div> :
                <Container fluid className="bg-light">
                    <Row className="mvh-90 align-items-center justify-content-center ob-ohidden">
                    {this.state.layout===1 ? 
                    <Col xl={4} lg={5} md={8}>
                        <div className='text-center mt-40'><img src={Logo} alt="Cart Rescue" className="mb-30 h-40" /></div>
                        <Card className="card-ob">
                            <div className="ob-circle-1"></div>
                            <div className="ob-circle-2"></div>
                            <div className="ob-circle-3"></div>
                            <Card.Body>


                                    <Card.Title>Reset password</Card.Title>
                                    <Card.Subtitle className="mb-30 text-secondary"></Card.Subtitle>
                                    {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}

                                    <Form className='onboarding' onSubmit={this.Code}>
                                        <Form.Group className="mb-20" controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" name="email" required={true} value={this.state.email} onChange={this.handleChange}  />
                                        </Form.Group>
                                       
                                       <div className='d-grid gap-2'>
                                            <Button className="" variant="primary"  type="submit" disabled={this.state.disabled}>
                                                Continue
                                            </Button>
                                            <NavLink className="btn btn-light mb-20" to="/login"> Back to log in </NavLink>
                                       </div>
                                        
                                    </Form>
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                        : "" }
                        {this.state.layout===2 ? 
                        <Col xl={4} lg={5} md={8}>
                            <div className='text-center mt-40'><img src={Logo} alt="Cart Rescue" className="mb-30 h-40" /></div>
                            <Card className="card-ob">
                                <div className="ob-circle-1"></div>
                                <div className="ob-circle-2"></div>
                                <div className="ob-circle-3"></div>
                                <Card.Body>


                                    <Card.Title>Recover password</Card.Title>
                                    <Card.Subtitle className="mb-30 text-secondary">Set the new password</Card.Subtitle>
                                    {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}
                                    <Card body className='mb-20'>
                                        <Container>
                                            <Row>
                                                <Col xs={"auto"} className="OBicon">
                                                    <IoPersonOutline/>
                                                </Col>
                                                <Col>
                                                    <h5>{this.state.name} <br/> <small>{this.state.email}</small></h5>
                                                </Col>
                                            </Row>
                                        </Container>
                                        
                                    </Card>

                                    <Form className='onboarding' onSubmit={this.UpdatePassword}>
                                        <Form.Group className="mb-20">
                                            <Form.Label className="w-100">Password</Form.Label>
                                            <Form.Control type="password" name="password" required={true} onChange={this.handleChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label className="w-100">Confirm password </Form.Label>
                                            <Form.Control type="password" name="cpassword" required={true}  onChange={this.handleChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label className="w-100">Secure code </Form.Label>
                                            <Form.Control type="text" name="code" required={true}  onChange={this.handleChange} />
                                            <Form.Text>We have emailed you the secured code to your email id.</Form.Text>
                                        </Form.Group>
                                       
                                       <div className='d-grid gap-2'>
                                            <Button className="mb-20 btn-ob btn-success" variant="success"  type="submit" disabled={this.state.disabled}>
                                               Continue
                                            </Button>
                                            <NavLink className="btn btn-light mb-20 btn-ob text-success" to="/onboarding/login"> Back to log in </NavLink>
                                            <p className="text-center">New to Cart Rescue? <NavLink to="/onboarding/register" className='text-success ml-5'>Register now</NavLink></p>
                                       </div>
                                        
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        : "" }
                    </Row>
                </Container>
                }
            </>
        );
    }

}

export default withRouter(Forgot);