import React, { Component} from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { Container, Col, Row, Card, Alert} from 'react-bootstrap';
import {FaArrowRight} from 'react-icons/fa'
import Logo from '../../assets/logo.svg'
import config from '../../config'

import Step2 from '../../assets/images/client/obf_step_2.png'
import Step3 from '../../assets/images/client/obf_step_3.png'
import Step5 from '../../assets/images/client/obf_step_5.png'
import Step6 from '../../assets/images/client/obf_step_6.png'
import Step8 from '../../assets/images/client/obf_step_8.png'

const TP_API_URL = config.TP_API_URL;


class OBComplete extends Component {
    state = {
        loading:false,
        error_code:0,
        error_msg:"",
        shop:{}
    };

    Verify_shop = () => {

        this.setState({
            error_code:0,
            error_msg:"",
            loading:true
        });

        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");

        const ax_headers = {
            params:{
                shop_id : tp_shop
            },
            headers: {
                token: tp_access_token
            }
        }

        axios.get(TP_API_URL+"client/shop/view", ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState(
                    {
                        loading:false,
                        shop : response.data
                    }
                );
            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    Update_shop = () => {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");

        const ax_headers = {
            headers: {
                token: tp_access_token
            }
        }

        axios.post(TP_API_URL+"client/shop/update", {
            shop_id : tp_shop,
            onboarding: 5
        }, ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.props.history.push("/onboarding/pages");
                this.setState(
                    {
                        loading:false
                    }
                );

            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    componentWillMount(){
        this.Verify_shop();
    }

    render() {
        return(
            <>
                {this.state.loading ? <div className="loading">Loading...</div> :
                <Container fluid className="bg-light OBContainer">
                    <Row className="mvh-90 align-items-center justify-content-center ob-ohidden">

                        <Col xl={4} lg={5} md={8}>
                            <div className='text-center mt-40'><img src={Logo} alt="Cart Rescue" className="mb-30 h-40" /></div>
                            <Card className="card-ob">
                                <div className="ob-circle-1"></div>
                                <div className="ob-circle-2"></div>
                                <div className="ob-circle-3"></div>
                                <Card.Body>

                                    <Card.Title>Finalize Onboarding</Card.Title>
                                    <Card.Subtitle className="mb-30 text-secondary">Configure your checkout. Follow the set up instructions below to ensure our platform works properly</Card.Subtitle>
                                    {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}
                                    <ol className='finalize'>
                                        <li>
                                            From your <a href={"https://"+this.state.shop.domain+"/admin/settings/checkout"} target="_blank" rel="noreferrer" className='text-success'>Shopify Admin</a>, go to <b>Settings <FaArrowRight/> Checkout</b>
                                        </li>
                                        <li>
                                            In the <b className='text-success'>Customer Contact Section</b>, select <b>Email</b>.
                                            <img src={Step2} alt="Finalize Step 2" />
                                        </li>
                                        <li>
                                            Scroll down to the <b className='text-success'>Form options</b>, and change "Shipping address phone number" to <b>Optional</b>.
                                            <img src={Step3} alt="Finalize Step 3" />
                                        </li>
                                        <li>
                                            Click <b>Save</b>.
                                        </li>   
                                        <li>
                                            Scroll to <b className='text-success'>Manage Checkout Language </b>
                                            <img src={Step5} alt="Finalize Step 5" />
                                        </li> 
                                        <li>
                                            In the filter bar, type in <b>"Phone"</b>.
                                            For <b>Phone Label</b> and <b>Optional Phone Label</b>, copy and paste this text to your settings: <b>Phone - get support, offers, and updates via text</b>
                                            <img src={Step6} alt="Finalize Step 6" />
                                        </li>
                                        <li>
                                            After you've updated both labels, make sure to confirm your changes. Click <b>Save</b> in the top right corner.
                                        </li>
                                        <li>
                                            Go to your checkout page to verify the changes were successful. It should look like this:
                                            <img src={Step8} alt="Finalize Step 8" />
                                        </li>
                                        <li>
                                            <b>You're all set!</b> We are ready to start texting your customers 🎉
                                        </li>               
                                    </ol>

                                    <div className='d-grid gap-2 wc-btn'>
                                        <NavLink to="/dashboard" className="btn btn-ob btn-success btn-ob-success" onClick={this.Update_shop} >
                                            Finish Setup
                                        </NavLink>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                }
            </>
        );
    }

}

export default OBComplete;