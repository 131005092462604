import React, { Component} from 'react';
import axios from 'axios';
import {withRouter } from 'react-router-dom';
import { Container, Col, Row, Button, Card, Form, Alert} from 'react-bootstrap';
import Logo from '../../assets/logo.svg'
import config from '../../config'

const TP_API_URL = config.TP_API_URL;


class OBPages extends Component {
    state = {
        loading:false,
        disabled:false,
        error_code:0,
        error_msg:"",
        shop:{},
        shopify_pages:[],
        privacy_policy:"",
        return_policy:"",
        exchange_policy:"",
        faq:"",
        terms:"",
        shipping_policy:"",
        cookie_policy:"",
        in_privacy_policy:0,
        in_return_policy:0,
        in_exchange_policy:0,
        in_faq:0,
        in_terms:0,
        in_shipping_policy:0,
        in_cookie_policy:0
    };
    
    Verify_shop = () => {
        this.setState({
            error_code:0,
            error_msg:"",
            loading:true
        });

        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");

        const ax_headers = {
            params:{
                shop_id : tp_shop
            },
            headers: {
                token: tp_access_token
            }
        }

        axios.get(TP_API_URL+"client/shop/view", ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.GetShopifyPages();

                let pages = JSON.parse(response.data.pages, true);
                this.setState(
                    {
                        shop : response.data,
                        privacy_policy:pages.privacy_policy,
                        return_policy:pages.return_policy,
                        exchange_policy:pages.exchange_policy,
                        faq:pages.faq,
                        terms:pages.terms,
                        shipping_policy:pages.shipping_policy,
                        cookie_policy:pages.cookie_policy,
                        in_privacy_policy:pages.in_privacy_policy,
                        in_return_policy:pages.in_return_policy,
                        in_exchange_policy:pages.in_exchange_policy,
                        in_faq:pages.in_faq,
                        in_terms:pages.in_terms,
                        in_shipping_policy:pages.in_shipping_policy,
                        in_cookie_policy:pages.in_cookie_policy
                    }
                );
                
                

            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    GetShopifyPages = () => {

        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");

        const ax_headers = {
            params:{
                shop_id : tp_shop
            },
            headers: {
                token: tp_access_token
            }
        }

        axios.get(TP_API_URL+"client/shop/shopify-pages", ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState(
                    {
                        loading : false,
                        shopify_pages : response.data
                    }
                );

            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    handleChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const id = target.id;
        if(value==='other_0') {
            this.setState({
                [id]:1
            });
        } else {
            this.setState({
                [name]: value,
                [id]:0
            });
        }
    }

    Update_shop = (event) => {
        event.preventDefault();
        this.setState({
                error_code:0,
                error_msg:"",
                disabled:true
        });

        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");

        const ax_headers = {
            headers: {
                token: tp_access_token
            }
        }

        const page_data = {
            in_privacy_policy:this.state.in_privacy_policy,
            in_return_policy:this.state.in_return_policy,
            in_exchange_policy:this.state.in_exchange_policy,
            in_faq:this.state.in_faq,
            in_terms:this.state.in_terms,
            in_shipping_policy:this.state.in_shipping_policy,
            in_cookie_policy:this.state.in_cookie_policy,
            privacy_policy : this.state.privacy_policy,
            return_policy:this.state.return_policy,
            exchange_policy:this.state.exchange_policy,
            faq:this.state.faq,
            terms:this.state.terms,
            shipping_policy:this.state.shipping_policy,
            cookie_policy:this.state.cookie_policy
        }

        axios.post(TP_API_URL+"client/shop/update", {
            shop_id : tp_shop,
            onboarding: 4,
            pages:page_data
        }, ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.props.history.push("/onboarding/complete");
                this.setState(
                    {
                        loading:false
                    }
                );

            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg,
                        disabled:false
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    componentWillMount(){
        this.Verify_shop();
    }
    
    render() {
        let optionTemplate = this.state.shopify_pages.map(v => (
            <option value={v.url} key={v.name}>{v.name}</option>
        ));

        return(
            <>
                {this.state.loading ? <div className="loading">Loading...</div> :
                <Container fluid className="bg-light">
                    <Row className="mvh-90 align-items-center justify-content-center ob-ohidden">
                        <Col xl={4} lg={5} md={8}>
                            <div className='text-center mt-40'><img src={Logo} alt="Cart Rescue" className="mb-30 h-40" /></div>
                            <Card className="card-ob">
                                <div className="ob-circle-1"></div>
                                <div className="ob-circle-2"></div>
                                <div className="ob-circle-3"></div>
                                <Card.Body>

                                    <Card.Title>Shop Pages</Card.Title>
                                    <Card.Subtitle className="mb-30 text-secondary">Provide us with basic shop page information</Card.Subtitle>
                                    {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}

                                    <Form className='onboarding' onSubmit={this.Update_shop}>
                                        <Form.Group className="mb-20">
                                            <Form.Label><b>Privacy policy</b></Form.Label>
                                            <Form.Select name="privacy_policy" id="in_privacy_policy" value={this.state.privacy_policy} onChange={this.handleChange}>
                                                <option value="">Select</option>
                                                {optionTemplate}
                                                <option value="other_0">Other</option>
                                            </Form.Select>
                                            {this.state.in_privacy_policy ? <Form.Control className='mt-10 py-0' type="text" name="privacy_policy" placeholder='Paste URL' value={this.state.privacy_policy} onChange={this.handleChange} /> : "" }
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label><b>Return policy</b></Form.Label>
                                            <Form.Select name="return_policy" id="in_return_policy" value={this.state.return_policy} onChange={this.handleChange}>
                                                <option value="">Select</option>
                                                {optionTemplate}
                                                <option value="other_0">Other</option>
                                            </Form.Select>
                                            {this.state.in_return_policy ? <Form.Control className='mt-10 py-0' type="text" name="return_policy" placeholder='Paste URL' value={this.state.return_policy} onChange={this.handleChange} /> : "" }
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label><b>Exchange policy</b></Form.Label>
                                            <Form.Select name="exchange_policy" id="in_exchange_policy" value={this.state.exchange_policy} onChange={this.handleChange}>
                                                <option value="">Select</option>
                                                {optionTemplate}
                                                <option value="other_0">Other</option>
                                            </Form.Select>
                                            {this.state.in_exchange_policy ? <Form.Control className='mt-10 py-0' type="text" name="exchange_policy" placeholder='Paste URL' value={this.state.exchange_policy} onChange={this.handleChange} /> : "" }
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label><b>FAQ</b></Form.Label>
                                            <Form.Select name="faq" id="in_faq" value={this.state.faq} onChange={this.handleChange}>
                                                <option value="">Select</option>
                                                {optionTemplate}
                                                <option value="other_0">Other</option>
                                            </Form.Select>
                                            {this.state.in_faq ? <Form.Control className='mt-10 py-0' type="text" name="faq" placeholder='Paste URL' value={this.state.faq} onChange={this.handleChange} /> : "" }
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label><b>Terms and conditions</b></Form.Label>
                                            <Form.Select name="terms" id="in_terms" value={this.state.terms} onChange={this.handleChange}>
                                                <option value="">Select</option>
                                                {optionTemplate}
                                                <option value="other_0">Other</option>
                                            </Form.Select>
                                            {this.state.in_terms ? <Form.Control className='mt-10 py-0' type="text" name="terms" placeholder='Paste URL' value={this.state.terms} onChange={this.handleChange} /> : "" }
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label><b>Shipping policy</b></Form.Label>
                                            <Form.Select name="shipping_policy" id="in_shipping_policy" value={this.state.shipping_policy} onChange={this.handleChange}>
                                                <option value="">Select</option>
                                                {optionTemplate}
                                                <option value="other_0">Other</option>
                                            </Form.Select>
                                            {this.state.in_shipping_policy ? <Form.Control className='mt-10 py-0' type="text" name="shipping_policy" placeholder='Paste URL' value={this.state.shipping_policy} onChange={this.handleChange} /> : "" }
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label><b>Cookie Policy</b></Form.Label>
                                            <Form.Select name="cookie_policy" id="in_cookie_policy" value={this.state.cookie_policy} onChange={this.handleChange}>
                                                <option value="">Select</option>
                                                {optionTemplate}
                                                <option value="other_0">Other</option>
                                            </Form.Select>
                                            {this.state.in_cookie_policy ? <Form.Control className='mt-10 py-0' type="text" name="cookie_policy" placeholder='Paste URL' value={this.state.cookie_policy} onChange={this.handleChange} /> : "" }
                                        </Form.Group>
                                       
                                       <div className='d-grid gap-2'>
                                            <Button className="btn-ob btn-ob-success" variant="success"  type="submit" disabled={this.state.disabled}>
                                                Continue
                                            </Button> 
                                            <Button className="btn-ob text-success" variant="light"  type="submit" disabled={this.state.disabled}>
                                                Skip
                                            </Button>                                 
                                        </div>
                                        
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                }
            </>
        );
    }

}

export default withRouter(OBPages);