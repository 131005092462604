
export const statesUS = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 
  'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 
  'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 
  'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 
  'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 
  'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 
  'Wisconsin', 'Wyoming'
];

export const statesCA = [
  'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 
  'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 
  'Northwest Territories', 'Nunavut', 'Yukon'
];

export const availableConditions = {
  'customer type': {
    operators: ['is', 'is not'],
    values: ['first time customer', 'spent over $100', 'spent over $250'],
  },
  'cart value': {
    operators: ['is', 'is not'],
    values: ['over $50', 'over $100', 'over $250', 'over $500'],
  },
  'total spent': {
    operators: ['is over', 'is under'],
    values: ['$50', '$250', '$500', '$1000'],
  },
  'product': {
    operators: ['is', 'is not'],
    values: [],
  },
  'country': {
    operators: ['is', 'is not'],
    values: ['United States', 'Canada'],
  },
  'state': {
    operators: ['is', 'is not'],
    values: statesUS,
  },
};