import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Dropdown, DropdownButton, Form, Row } from "react-bootstrap";
import ConditionsComponent from "./Condition";

export const SegmentForm = ({
    isEditing,
    segment,
    onUpdate,
    onCreate,
    onDelete,
    onBack,
    setSegment,
}) => {

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSegment({ ...segment, [name]: value });
    };

    const handleSelectChange = (eventKey) => {
        setSegment({
            ...segment,
            discount_type: eventKey
        });
    };
  
    const handleSubmit = () => {
      if (isEditing) {
        onUpdate(segment.id);
      } else {
        onCreate();
      }
    };

    const setConditions = (newConditions) => {
        setSegment({ ...segment,  rules: newConditions });
    }
  
    return (
        <>
            <Container fluid>
            <Row>
                <Col sm={12}>
                    <h3 className="page-heading sidebar-btn" onClick={onBack}>
                        <FontAwesomeIcon icon={faArrowLeft} className="menuicon mr-5" /> {isEditing ? 'Edit Segment' : 'Add Segment'}
                    </h3>
                </Col>
            </Row>
            </Container>

            <Container>
            <Row className="segment-name w-full" lg={12}>
                <Col sm={2} lg={6}>
                    <Row>
                        <Card body className='mb-20 px'>
                            <Form.Group controlId="segmentName">
                                <Form.Label>Segment Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="name"
                                    placeholder="Ex: Exclude Product A"
                                    onChange={e => handleInputChange(e)}
                                    value={segment?.name || ''}
                                />
                            </Form.Group>
                        </Card>
                    </Row>
                    <Row className="setting-conditions">
                        <Card body className='mb-20 mr-[-5]'>
                            <Form.Label className='mb-10'>Conditions</Form.Label>
                            <ConditionsComponent
                                conditions={segment?.rules || []} 
                                setConditions={setConditions} 
                            />
                        </Card>
                    </Row>
                </Col>
                <Col sm={9} lg={6}>
                    <Card body className='mb-20 px'>
                        <Form.Group controlId="formAction" className='mb-15'>
                        <Form.Label className="text-size-medium figtree">Action</Form.Label>
                            <div>
                            <Form.Text className="text-muted ">What would you like to do for this cart matching this shipment</Form.Text>
                            <Row className="offer-discount-border-exclude-this-cart add-segment-text-margin-top2">
                                    <Col
                                        sm='auto'
                                        className="offer-discount-border"
                                        style={{
                                            border: `1px solid ${segment?.action === "offer_discount" ? '#00A854' : '#ccc'}`, 
                                            borderBottomLeftRadius: '7px',  
                                            borderTopLeftRadius: '7px',   
                                        }}
                                    >
                                        <Form.Check 
                                            type="radio"
                                            name="action"
                                            id="offer-discount-radio"
                                            label="Offer Discount"
                                            value="offer_discount"
                                            onChange={e => handleInputChange(e)}
                                            checked={segment?.action === "offer_discount"}
                                            className="figtree"
                                        />
                                    </Col>
                                    <Col
                                        sm='auto'
                                        className="exclude-this-cart"
                                        style={{
                                            border: `1px solid ${segment?.action === "exclude_this_cart" ? '#00A854' : '#ccc'}`, 
                                            borderBottomRightRadius: '7px',  
                                            borderTopRightRadius: '7px',   
                                        }}
                                    >
                                        <Form.Check 
                                            type="radio"
                                            name="action"
                                            id="exclude-cart-radio"
                                            label="Exclude This Cart"
                                            value="exclude_this_cart"
                                            onChange={e => handleInputChange(e)}
                                            checked={segment?.action === "exclude_this_cart"}
                                            className="figtree"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Form.Group>
                        {segment?.action === "offer_discount" && (
                            // we show here the discount field
                            <Form.Group controlId="selectDiscount" className='mb-25'>
                                <Form.Label>Select a Discount</Form.Label>
                                <div className="">
                                    <Form.Text className="text-muted add-segment-text-margin-top ">
                                        Note: A unique code will be generated by default for each cart
                                    </Form.Text>
                                </div>
                                <Row className='add-segment-text-margin-top'>
                                    <Col xs={8}>
                                        <DropdownButton 
                                            id="dropdown-basic-button" 
                                            title={segment.discount_type === 'percentage' ? 'Percentage' : 'Value'}
                                            className="custom-dropdown figtree"
                                            onSelect={(eventKey) => handleSelectChange(eventKey)}
                                        >
                                            <Dropdown.Item eventKey="percentage" >Percentage</Dropdown.Item>
                                            <Dropdown.Item eventKey="actual_value" >Value</Dropdown.Item>
                                        </DropdownButton>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Control 
                                            type="number" 
                                            value={segment.discount_value}
                                            name="discount_value"
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        )}
                        <div className="add-segment-button-flex">
                            <Button 
                                id="segment-save-button"
                                variant="primary" 
                                onClick={handleSubmit}
                            >
                                {isEditing ? 'Save Changes' : 'Add Segment'}
                            </Button>
                        </div>
                    </Card>
                </Col>
                <Col sm={12} lg={12}>
                {isEditing && (
                    <Button 
                    variant="danger" 
                    size='sm'
                    onClick={onDelete}
                    >
                        Delete
                    </Button>
                )}
                </Col>
            </Row>
            </Container>
        </>
    );
}
