import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card} from 'react-bootstrap';

import config from '../../config'
const TP_API_URL = config.TP_API_URL;
var dateFormat = require("dateformat");
let source = axios.CancelToken.source();

const columns = [
    {
        name: 'Name',
        selector: 'cart_name',
        sortable: false,
        right: false,
        cell: row => <div data-tag="allowRowEvents"><div data-tag="allowRowEvents" style={{ fontWeight: 700 }}>{row.cart_name}</div></div>,
    },
    {
        name: 'Phone Number',
        selector: 'sms_to',
        sortable: false,
        right: false,
        cell: row => <div data-tag="allowRowEvents">{row.sms_to}</div>,
    },
    {
        name: 'Last Contacted',
        selector: 'updated_on',
        sortable: false,
        right: false,
        cell: row =><div data-tag="allowRowEvents">{row.updated_on ? dateFormat(row.updated_on) : ""}</div>,
    },
    {
        name: 'Total Messages',
        selector: 'sms_total',
        sortable: false,
        right: false
    },

    {
        name: 'Status',
        selector: 'cart_status',
        sortable: false,
        right: false,
        cell: row =><div data-tag="allowRowEvents"><span className={'cart-status ' + row.cart_status}>{row.cart_status}</span></div>,
    }
];

class Conversations extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        loading:true,
        loading_text:"Loading...",
        tableloading:false,
        data:[{}],
        conversations:[{}],
        total:0,
        page:1
    };


    setPage = (page) => {
        this.setState({page:page});
    }

    getData = () => {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        this.setState({tableloading:true});
        if (source) { source.cancel();}
        source = axios.CancelToken.source();
        axios.get(TP_API_URL+"client/conversations/list", {
            params:{shop_id:tp_shop, page:this.state.page},
            headers:{token: tp_access_token},
            cancelToken: source.token
        }).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                this.setState({
                    loading:false,
                    tableloading:false,
                    data:response.data,
                    conversations:response.conversations
                });
            } else {
                this.setState({
                    loading_text:response.error_msg
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
            this.setState({
                loading_text:e.message
            });
        });
    }


    componentDidMount(){
        let upage = parseInt(this.props.match.params.page);
        if(!upage){ upage=1;}
        this.setState({page:upage});
        this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevState.page!==this.state.page){
            this.getData();
        }
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }

    routeChange=(data)=> {
        this.props.history.push("/dashboard/conversation/view/"+data.id);
    }

    
    render () {
        return(
            this.state.loading ? <div className='loading2'><p>{this.state.loading_text}</p></div> :
            <>
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <h3 className="page-heading sidebar-btn">
                                Conversations
                            </h3>
                        </Col>
                    </Row>
                </Container>

                <Container fluid>
                    <Row>
                        <Col className='billing'>
                            <Card body>
                                <DataTable
                                    noHeader={true}
                                    columns={columns}
                                    data={this.state.conversations}
                                    highlightOnHover={true}
                                    pointerOnHover={true}
                                    onRowClicked={this.routeChange}
                                    pagination={true}
                                    paginationServer={true}
                                    page={this.state.page}
                                    paginationTotalRows={this.state.data.total_row}
                                    paginationPerPage={50}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true
                                    }}
                                    onChangePage={page => this.setPage(page)}
                                    paginationDefaultPage={this.state.page}
                                    progressPending={this.state.tableloading}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withRouter(Conversations);