import React, { Component} from 'react';
import { Container, Col, Row, Card, Tab, Nav, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';

import config from '../../config'

import {AiOutlineUser} from 'react-icons/ai'
import {MdOutlineDiscount} from 'react-icons/md'
import { HiOutlineClipboardList, HiOutlineLockClosed, HiStatusOnline } from 'react-icons/hi'

const TP_API_URL = config.TP_API_URL;

let source = axios.CancelToken.source();
class Settings extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        loading:false,
        loading_text:"Loading...",
        disabled:false,
        success:0,
        error_code:0,
        error_msg:"",
        shop:{},
        shop_name:"",
        shop_email:"",
        shop_phone:"",
        shop_utm:"",

        shopify_pages:[],
        privacy_policy:"",
        return_policy:"",
        exchange_policy:"",
        faq:"",
        terms:"",
        shipping_policy:"",
        cookie_policy:"",
        in_privacy_policy:0,
        in_return_policy:0,
        in_exchange_policy:0,
        in_faq:0,
        in_terms:0,
        in_shipping_policy:0,
        in_cookie_policy:0,

        discount_type:1,
        discount_value:0,
        post_purchase:0,
        discount_value_pp:0,
        sms_status:0,

        code:"",
        password:"",
        cpassword:"",
        pw_error_code:0,
        pw_error_msg:"",
        pw_email:"",
        pw_disabled:false,
        pw_code:0,
        pw_success:0
    };

    getData = () => {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        this.setState({loading:true});
        if (source) { source.cancel();}
        source = axios.CancelToken.source();
        axios.get(TP_API_URL+"client/shop/view", {
            params:{shop_id:tp_shop},
            headers:{token: tp_access_token},
            cancelToken: source.token
        }).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                let pages = JSON.parse(response.data.pages, true);
                this.setState({
                    loading:false,
                    shop : response.data,
                    shop_name:response.data.shop_name,
                    shop_email:response.data.shop_email,
                    shop_phone:response.data.shop_phone,
                    shop_utm:response.data.shop_utm,
                    privacy_policy:pages.privacy_policy,
                    return_policy:pages.return_policy,
                    exchange_policy:pages.exchange_policy,
                    faq:pages.faq,
                    terms:pages.terms,
                    shipping_policy:pages.shipping_policy,
                    cookie_policy:pages.cookie_policy,
                    in_privacy_policy:pages.in_privacy_policy,
                    in_return_policy:pages.in_return_policy,
                    in_exchange_policy:pages.in_exchange_policy,
                    in_faq:pages.in_faq,
                    in_terms:pages.in_terms,
                    in_shipping_policy:pages.in_shipping_policy,
                    in_cookie_policy:pages.in_cookie_policy,
                    discount_type:response.data.discount_type,
                    discount_value:response.data.discount_value,
                    sms_status:response.data.sms_status,
                    pw_email:response.data.account_email,
                    post_purchase:response.data.post_purchase,
                    discount_value_pp:response.data.discount_value_pp,
                });
            } else {
                this.setState({
                    loading_text:response.error_msg
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
            this.setState({
                loading_text:e.message
            });
        });
    }


    Update_shop = (event) => {
        event.preventDefault();
        this.setState({
                error_code:0,
                error_msg:"",
                disabled:true,
                success:0
        });

        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");

        const ax_headers = {
            headers: {
                token: tp_access_token
            }
        }

        const page_data = {
            in_privacy_policy:this.state.in_privacy_policy,
            in_return_policy:this.state.in_return_policy,
            in_exchange_policy:this.state.in_exchange_policy,
            in_faq:this.state.in_faq,
            in_terms:this.state.in_terms,
            in_shipping_policy:this.state.in_shipping_policy,
            in_cookie_policy:this.state.in_cookie_policy,
            privacy_policy : this.state.privacy_policy,
            return_policy:this.state.return_policy,
            exchange_policy:this.state.exchange_policy,
            faq:this.state.faq,
            terms:this.state.terms,
            shipping_policy:this.state.shipping_policy,
            cookie_policy:this.state.cookie_policy
        }

        axios.post(TP_API_URL+"client/shop/update", {
            shop_id : tp_shop,
            shop_name:this.state.shop_name,
            shop_email:this.state.shop_email,
            shop_phone:this.state.shop_phone,
            shop_utm:this.state.shop_utm,
            discount_type:this.state.discount_type,
            discount_value:this.state.discount_value,
            post_purchase:this.state.post_purchase,
            discount_value_pp:this.state.discount_value_pp,
            sms_status:this.state.sms_status,
            pages:page_data
        }, ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState(
                    {
                        loading:false,
                        success:1,
                        disabled:false
                    }
                );

            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg,
                        disabled:false
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    GetShopifyPages = () => {

        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");

        const ax_headers = {
            params:{
                shop_id : tp_shop
            },
            headers: {
                token: tp_access_token
            }
        }

        axios.get(TP_API_URL+"client/shop/shopify-pages", ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState(
                    {
                        loading : false,
                        shopify_pages : response.data
                    }
                );

            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    getCode = (event) => {
        event.preventDefault();
        this.setState({
            pw_error_code:0,
            pw_error_msg:"",
            pw_disabled:true
        });

        axios.post(TP_API_URL+'client/users/code', { 
            email:this.state.pw_email
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState({
                    pw_error_code:0,
                    pw_error_msg:"",
                    pw_email:response.data.email,
                    pw_disabled:false,
                    pw_code:1

                });
            } else {
                this.setState({
                    pw_error_code:response.error_code,
                    pw_error_msg:response.error_msg,
                    pw_disabled:false
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    UpdatePassword = (event) => {
        event.preventDefault();
        this.setState({
            pw_error_code:0,
            pw_error_msg:"",
            pw_disabled:true,
            pw_success:0
        });

        if(this.state.password!==this.state.cpassword){
            this.setState({
                pw_error_code:1,
                pw_error_msg:"Your password does not match",
                pw_disabled:false
            });

        } else {

            axios.post(TP_API_URL+'client/users/change-password', { 
                code:this.state.code,
                email:this.state.pw_email,
                password:this.state.password,
                password_confirmation:this.state.cpassword
            })
            .then((res) => {
                const response = res.data;
                if(response.error_code===0){
                    localStorage.setItem('tp_access_token', response.data.token);
                    this.setState({
                        pw_error_code:response.error_code,
                        pw_error_msg:response.error_msg,
                        pw_disabled:false,
                        pw_success:1,
                        password:"",
                        cpassword:"",
                        code:""
                    });
                } else {
                    this.setState({
                        pw_error_code:response.error_code,
                        pw_error_msg:response.error_msg,
                        pw_disabled:false
                    });
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
        }
    }


    componentDidMount(){
        this.getData();
        this.GetShopifyPages();
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }


    handleChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if(name==='shop_phone') {
            const regEx = /^(\s*|[\d +]+)$/;
            if (regEx.test(value)) {
                this.setState({
                    [name]: value
                });
            }
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    handlePageChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const id = target.id;
        if(value==='other_0') {
            this.setState({
                [id]:1
            });
        } else {
            this.setState({
                [name]: value,
                [id]:0
            });
        }
    }


    hidealert = () => {
        this.setState(
            {
                loading:false,
                disabled:false,
                success:0,
                error_code:0,
                error_msg:"",
                pw_success:0,
                pw_error_code:0,
                pw_error_msg:"",
                password:"",
                cpassword:"",
                code:""
            }
        );
    }


    render() {

        let optionTemplate = this.state.shopify_pages.map(v => (
            <option value={v.url} key={v.name}>{v.name}</option>
        ));

        return(
            this.state.loading ? <div className='loading2'><p>{this.state.loading_text}</p></div> :
            <> 
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <h3 className="page-heading sidebar-btn">
                                Settings
                            </h3>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <Col>
                        <Tab.Container id="setting-tab" defaultActiveKey="first" >
                            <Row className="setting-tab">
                                <Col sm={3}>
                                    <Card body className='mb-20'>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first" onClick={this.hidealert}>
                                                    <Row>
                                                        <Col><AiOutlineUser className='mr-5 mb-3' /> Account Info</Col>
                                                    </Row>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second" onClick={this.hidealert}>
                                                    <Row>
                                                        <Col><HiOutlineClipboardList className='mr-5 mb-3' /> Shop Pages</Col>
                                                    </Row>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third" onClick={this.hidealert}>
                                                    <Row>
                                                        <Col><MdOutlineDiscount className='mr-5 mb-3' /> Discounts</Col>
                                                    </Row>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="fourth" onClick={this.hidealert}>
                                                    <Row>
                                                        <Col><HiStatusOnline className='mr-5 mb-3' /> Status</Col>
                                                    </Row>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="fifth">
                                                    <Row>
                                                        <Col><HiOutlineLockClosed className='mr-5 mb-3' /> Change Password</Col>
                                                    </Row>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Card>
                                </Col>
                                <Col sm={9} lg={6}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <Container className="mb-15">
                                                    <Row>
                                                        <Col>
                                                            {this.state.success > 0 ? <Alert variant="success">Shop updated successfully!</Alert> : ""}
                                                            {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}
                                                            <Form onSubmit={this.Update_shop}>
                                                                <Form.Group className="mb-20" >
                                                                    <Form.Label><b>Store Name</b></Form.Label>
                                                                    <Form.Control type="text" name="shop_name" value={this.state.shop_name} onChange={this.handleChange}  />
                                                                    <Form.Text className="text-muted">
                                                                        The name of your store as it will appear in the text messages to your customers
                                                                    </Form.Text>
                                                                </Form.Group>

                                                                <Form.Group className="mb-20" >
                                                                    <Form.Label><b>Customer Support Email</b></Form.Label>
                                                                    <Form.Control type="email" name="shop_email" required={true} value={this.state.shop_email} onChange={this.handleChange} />
                                                                    <Form.Text className="text-muted">
                                                                        Where your customers email your store for support
                                                                    </Form.Text>
                                                                </Form.Group>

                                                                <Form.Group className="mb-20" >
                                                                    <Form.Label><b>Customer Support Phone Number</b></Form.Label>
                                                                    <Form.Control type="text" name="shop_phone" value={this.state.shop_phone} onChange={this.handleChange} maxLength="15" />
                                                                    <Form.Text>Where your customers contact your store for support</Form.Text>
                                                                </Form.Group>

                                                                <Form.Group className="mb-20" >
                                                                    <Form.Label><b>Google analytics UTM code</b></Form.Label>
                                                                    <Form.Control type="text" name="shop_utm" value={this.state.shop_utm} onChange={this.handleChange} />
                                                                </Form.Group>

                                                                <Button variant="primary" type="submit" disabled={this.state.disabled}>
                                                                    Save
                                                                </Button>
                                                            </Form>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <Container className="mb-15">
                                                    <Row>
                                                        <Col>
                                                            {this.state.success > 0 ? <Alert variant="success">Shop updated successfully!</Alert> : ""}
                                                            {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}
                                                            <Form onSubmit={this.Update_shop}>
                                                                <Form.Group className="mb-20">
                                                                    <Form.Label><b>Privacy policy</b></Form.Label>
                                                                    <Form.Select name="privacy_policy" id="in_privacy_policy" value={this.state.privacy_policy} onChange={this.handlePageChange}>
                                                                        <option value="">Select</option>
                                                                        {optionTemplate}
                                                                        <option value="other_0">Other</option>
                                                                    </Form.Select>
                                                                    {this.state.in_privacy_policy ? <Form.Control className='mt-10 py-0' type="text" name="privacy_policy" placeholder='Paste URL' value={this.state.privacy_policy} onChange={this.handleChange} /> : "" }
                                                                </Form.Group>

                                                                <Form.Group className="mb-20">
                                                                    <Form.Label><b>Return policy</b></Form.Label>
                                                                    <Form.Select name="return_policy" id="in_return_policy" value={this.state.return_policy} onChange={this.handlePageChange}>
                                                                        <option value="">Select</option>
                                                                        {optionTemplate}
                                                                        <option value="other_0">Other</option>
                                                                    </Form.Select>
                                                                    {this.state.in_return_policy ? <Form.Control className='mt-10 py-0' type="text" name="return_policy" placeholder='Paste URL' value={this.state.return_policy} onChange={this.handleChange} /> : "" }
                                                                </Form.Group>

                                                                <Form.Group className="mb-20">
                                                                    <Form.Label><b>Exchange policy</b></Form.Label>
                                                                    <Form.Select name="exchange_policy" id="in_exchange_policy" value={this.state.exchange_policy} onChange={this.handlePageChange}>
                                                                        <option value="">Select</option>
                                                                        {optionTemplate}
                                                                        <option value="other_0">Other</option>
                                                                    </Form.Select>
                                                                    {this.state.in_exchange_policy ? <Form.Control className='mt-10 py-0' type="text" name="exchange_policy" placeholder='Paste URL' value={this.state.exchange_policy} onChange={this.handleChange} /> : "" }
                                                                </Form.Group>

                                                                <Form.Group className="mb-20">
                                                                    <Form.Label><b>FAQ</b></Form.Label>
                                                                    <Form.Select name="faq" id="in_faq" value={this.state.faq} onChange={this.handlePageChange}>
                                                                        <option value="">Select</option>
                                                                        {optionTemplate}
                                                                        <option value="other_0">Other</option>
                                                                    </Form.Select>
                                                                    {this.state.in_faq ? <Form.Control className='mt-10 py-0' type="text" name="faq" placeholder='Paste URL' value={this.state.faq} onChange={this.handleChange} /> : "" }
                                                                </Form.Group>

                                                                <Form.Group className="mb-20">
                                                                    <Form.Label><b>Terms and conditions</b></Form.Label>
                                                                    <Form.Select name="terms" id="in_terms" value={this.state.terms} onChange={this.handlePageChange}>
                                                                        <option value="">Select</option>
                                                                        {optionTemplate}
                                                                        <option value="other_0">Other</option>
                                                                    </Form.Select>
                                                                    {this.state.in_terms ? <Form.Control className='mt-10 py-0' type="text" name="terms" placeholder='Paste URL' value={this.state.terms} onChange={this.handleChange} /> : "" }
                                                                </Form.Group>

                                                                <Form.Group className="mb-20">
                                                                    <Form.Label><b>Shipping policy</b></Form.Label>
                                                                    <Form.Select name="shipping_policy" id="in_shipping_policy" value={this.state.shipping_policy} onChange={this.handlePageChange}>
                                                                        <option value="">Select</option>
                                                                        {optionTemplate}
                                                                        <option value="other_0">Other</option>
                                                                    </Form.Select>
                                                                    {this.state.in_shipping_policy ? <Form.Control className='mt-10 py-0' type="text" name="shipping_policy" placeholder='Paste URL' value={this.state.shipping_policy} onChange={this.handleChange} /> : "" }
                                                                </Form.Group>

                                                                <Form.Group className="mb-20">
                                                                    <Form.Label><b>Cookie Policy</b></Form.Label>
                                                                    <Form.Select name="cookie_policy" id="in_cookie_policy" value={this.state.cookie_policy} onChange={this.handlePageChange}>
                                                                        <option value="">Select</option>
                                                                        {optionTemplate}
                                                                        <option value="other_0">Other</option>
                                                                    </Form.Select>
                                                                    {this.state.in_cookie_policy ? <Form.Control className='mt-10 py-0' type="text" name="cookie_policy" placeholder='Paste URL' value={this.state.cookie_policy} onChange={this.handleChange} /> : "" }
                                                                </Form.Group>
                                                            
                                                                <Button variant="primary" type="submit" disabled={this.state.disabled}>
                                                                        Save
                                                                </Button>
                                                            </Form>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <Container className="mb-15">
                                                    <Row>
                                                        <Col>
                                                            {this.state.success > 0 ? <Alert variant="success">Shop updated successfully!</Alert> : ""}
                                                            {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}
                                                            <Form onSubmit={this.Update_shop}>

                                                                <Form.Group className="mb-20" >
                                                                    <Form.Label><b>Discount Type</b></Form.Label>
                                                                    <Form.Select type="text" name="discount_type" aria-label="Discount Type" value={this.state.discount_type} onChange={this.handleChange}>
                                                                        <option value="1">% Off Discount Code</option>
                                                                        <option value="2">Free Shipping</option>
                                                                    </Form.Select>
                                                                </Form.Group>

                                                                {Number(this.state.discount_type) === 1 ?

                                                                <Form.Group className="mb-20" >
                                                                    <Form.Label><b>Discount Value (%)</b></Form.Label>
                                                                    <Form.Control type="number" name="discount_value" min="5" max="50" required={true} value={this.state.discount_value} onChange={this.handleChange} />
                                                                    <Form.Text className="text-muted">
                                                                        Modify the percentage-based discount to provide to your customers who abandoed checkout
                                                                    </Form.Text>
                                                                </Form.Group>

                                                                : "" }

                                                                {/* <Form.Group className="mb-20" >
                                                                    <Form.Label><b>Post Purchase Discount</b></Form.Label>
                                                                    <Form.Select type="text" name="post_purchase" aria-label="Post Purchase Discount" value={this.state.post_purchase} onChange={this.handleChange}>
                                                                        <option value="1">On</option>
                                                                        <option value="0">Off</option>
                                                                    </Form.Select>
                                                                    
                                                                    <Form.Text className="text-muted">
                                                                        After a customer makes their first purchase on your store we can text them with a discount code for their next purchase to increase LTV and Returning Customer Rate.
                                                                    </Form.Text>
                                                                </Form.Group> */}

                                                                {/* {Number(this.state.post_purchase) === 1 ?

                                                                <Form.Group className="mb-20" >
                                                                    <Form.Label><b>Post Purchase Discount Value (%)</b></Form.Label>
                                                                    <Form.Control type="number" name="discount_value_pp" min="5" max="50" required={true} value={this.state.discount_value_pp} onChange={this.handleChange} />
                                                                </Form.Group>

                                                                : "" } */}
                                                            
                                                                <Button variant="primary" type="submit" disabled={this.state.disabled}>
                                                                    Save
                                                                </Button>
                                                            </Form>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="fourth">
                                                <Container className="mb-15">
                                                    <Row>
                                                        <Col>
                                                            {this.state.success > 0 ? <Alert variant="success">Shop updated successfully!</Alert> : ""}
                                                            {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}
                                                            <Form onSubmit={this.Update_shop}>
                                                                <Form.Group className="mb-20" >
                                                                    <Form.Label><b>Texting status</b></Form.Label>
                                                                    <Form.Select type="text" name="sms_status" aria-label="Texting status" value={this.state.sms_status} onChange={this.handleChange}>
                                                                        <option value="1">On</option>
                                                                        <option value="0">Off</option>
                                                                    </Form.Select>
                                                                    <Form.Text className="text-muted">
                                                                        Temporarily disable outgoing texts. This does not pause billing
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            
                                                                <Button variant="primary" type="submit" disabled={this.state.disabled}>
                                                                    Save
                                                                </Button>
                                                            </Form>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="fifth">
                                                <Container className="mb-15">
                                                    <Row>
                                                        <Col>
                                                            {this.state.pw_success > 0 ? <Alert variant="success">Password updated successfully!</Alert> : ""}
                                                            {this.state.pw_error_code > 0 ? <Alert variant="danger">{this.state.pw_error_msg}</Alert> : ""}
                                                            <Form onSubmit={this.UpdatePassword}>
                                                                <Form.Group className="mb-20">
                                                                    <Form.Label className="w-100"><b>Password</b></Form.Label>
                                                                    <Form.Control type="password" name="password" required={true} value={this.state.password} onChange={this.handleChange} />
                                                                </Form.Group>

                                                                <Form.Group className="mb-20">
                                                                    <Form.Label className="w-100"><b>Confirm password</b></Form.Label>
                                                                    <Form.Control type="password" name="cpassword" required={true} value={this.state.cpassword} onChange={this.handleChange} />
                                                                </Form.Group>

                                                                <Button variant="primary" type="submit" disabled={this.state.pw_disabled}>
                                                                    Save
                                                                </Button>
                                                            </Form>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Tab.Pane>
                                        </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                        </Col>
                    </Row>
                </Container>
               
            </>
        
        )
    }
}

export default Settings;