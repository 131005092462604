import React, { Component} from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row, Button, Card, Form, Alert} from 'react-bootstrap';
import Logo from '../../assets/logo.svg'
import config from '../../config'
import {IoMailOutline, IoCallOutline} from 'react-icons/io5';

const TP_API_URL = config.TP_API_URL;


class OBContactInfo extends Component {

    state = {
        loading:false,
        disabled:false,
        error_code:0,
        error_msg:"",
        shop:{},
        cs_email:"",
        cs_phone:""
    };

    Verify_shop = () => {
        this.setState({
            error_code:0,
            error_msg:"",
            loading:true
        });

        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");

        const ax_headers = {
            params:{
                shop_id : tp_shop
            },
            headers: {
                token: tp_access_token
            }
        }

        axios.get(TP_API_URL+"client/shop/view", ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState(
                    {
                        loading:false,
                        shop : response.data,
                        cs_email:response.data.shop_email,
                        cs_phone:response.data.shop_phone
                    }
                );
            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    handleChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(name==='cs_phone') {
            const regEx = /^(\s*|[\d +]+)$/;
            if (regEx.test(value)) {
                this.setState({
                    [name]: value
                });
            }
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    Update_shop = (event) => {
        event.preventDefault();
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        const ax_headers = {
            headers: {
                token: tp_access_token
            }
        }

        this.setState({
            error_code:0,
            error_msg:"",
            disabled: true
        });

        axios.post(TP_API_URL+"client/shop/update", {
            shop_id : tp_shop,
            onboarding: 3,
            shop_email:this.state.cs_email,
            shop_phone:this.state.cs_phone
        }, ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.props.history.push("/onboarding/pages");
                this.setState(
                    {
                        loading:false,
                        disabled:false
                    }
                );

            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg,
                        disabled:false
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    componentWillMount(){
        this.Verify_shop();
    }

    render() {
        return(
            <>
                {this.state.loading ? <div className="loading">Loading...</div> :
                <Container fluid className="bg-light">
                    <Row className="mvh-90 align-items-center justify-content-center ob-ohidden">
                        <Col xl={4} lg={5} md={8}>
                            <div className='text-center mt-40'><img src={Logo} alt="Cart Rescue" className="mb-30 h-40" /></div>
                            <Card className="card-ob">
                                <div className="ob-circle-1"></div>
                                <div className="ob-circle-2"></div>
                                <div className="ob-circle-3"></div>
                                <Card.Body>

                                    <Card.Title>Contact Information</Card.Title>
                                    <Card.Subtitle className="mb-30 text-secondary">Provide us with basic shop contact information</Card.Subtitle>
                                    {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}
                                    <Form className='onboarding' onSubmit={this.Update_shop}>
                                        <Form.Group className="mb-20">
                                            <Form.Label><b><IoMailOutline className='mr-5'/> Customer Support Email</b></Form.Label>
                                            <Form.Control type="email" name="cs_email" required={true} value={this.state.cs_email} onChange={this.handleChange}/>
                                            <Form.Text>Where your customers email your store for support</Form.Text>
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label><b><IoCallOutline className='mr-5'/> Customer Support Phone Number</b><small>(optional)</small></Form.Label>
                                            <Form.Control type="text" name="cs_phone" value={this.state.cs_phone} onChange={this.handleChange} maxLength="15" />
                                            <Form.Text>Where your customers contact your store for support</Form.Text>
                                        </Form.Group>
                                       
                                       <div className='d-grid gap-2'>
                                            <Button className="btn-ob btn-ob-success" variant="success"  type="submit" disabled={this.state.disabled}>
                                                Continue
                                            </Button>                                 
                                        </div>
                                        
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                }
            </>
        );
    }

}

export default withRouter(OBContactInfo);