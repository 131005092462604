import React from "react";

export function IconEdit() {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M23.0455 9.84799C23.334 9.72849 23.6432 9.66699 23.9555 9.66699C24.2677 9.66699 24.5769 9.72849 24.8654 9.84799C25.1539 9.96748 25.416 10.1426 25.6368 10.3634C25.8576 10.5842 26.0328 10.8463 26.1522 11.1348C26.2717 11.4233 26.3332 11.7325 26.3332 12.0448C26.3332 12.357 26.2717 12.6662 26.1522 12.9547C26.0328 13.2432 25.8576 13.5053 25.6368 13.7261L20.8643 18.4986C20.5012 18.8617 20.0397 19.1084 19.5369 19.209C19.5369 19.209 19.537 19.209 19.5369 19.209L17.3303 19.6506C17.0571 19.7053 16.7746 19.6199 16.5776 19.4229C16.3805 19.2259 16.295 18.9435 16.3495 18.6703L16.7904 16.4636C16.8913 15.9608 17.1388 15.4984 17.5016 15.1359L22.2741 10.3634C22.4949 10.1426 22.757 9.96748 23.0455 9.84799ZM23.9555 11.3337C23.8621 11.3337 23.7696 11.3521 23.6833 11.3878C23.5971 11.4235 23.5187 11.4759 23.4526 11.5419L22.6977 12.2968L23.7034 13.3025L24.4583 12.5476C24.5243 12.4816 24.5767 12.4032 24.6124 12.3169C24.6482 12.2306 24.6666 12.1382 24.6666 12.0448C24.6666 11.9514 24.6482 11.8589 24.6124 11.7726C24.5767 11.6864 24.5243 11.608 24.4583 11.5419C24.3923 11.4759 24.3139 11.4235 24.2276 11.3878C24.1413 11.3521 24.0489 11.3337 23.9555 11.3337ZM22.5249 14.481L21.5192 13.4754L18.6801 16.3144C18.5501 16.4443 18.4609 16.6102 18.4247 16.7905C18.4246 16.7906 18.4247 16.7904 18.4247 16.7905L18.2288 17.7711L19.2098 17.5747C19.3904 17.5386 19.5556 17.4503 19.6858 17.3201L22.5249 14.481Z" fill="#3E5174"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13 13.0007C12.779 13.0007 12.567 13.0884 12.4107 13.2447C12.2545 13.401 12.1667 13.613 12.1667 13.834V23.0007C12.1667 23.2217 12.2545 23.4336 12.4107 23.5899C12.567 23.7462 12.779 23.834 13 23.834H22.1667C22.3877 23.834 22.5996 23.7462 22.7559 23.5899C22.9122 23.4336 23 23.2217 23 23.0007V20.5007C23 20.0404 23.3731 19.6673 23.8333 19.6673C24.2936 19.6673 24.6667 20.0404 24.6667 20.5007V23.0007C24.6667 23.6637 24.4033 24.2996 23.9344 24.7684C23.4656 25.2373 22.8297 25.5007 22.1667 25.5007H13C12.337 25.5007 11.7011 25.2373 11.2322 24.7684C10.7634 24.2996 10.5 23.6637 10.5 23.0007V13.834C10.5 13.1709 10.7634 12.5351 11.2322 12.0662C11.7011 11.5974 12.337 11.334 13 11.334H15.5C15.9602 11.334 16.3333 11.7071 16.3333 12.1673C16.3333 12.6276 15.9602 13.0007 15.5 13.0007H13Z" fill="#3E5174"/>
        </svg>
    )
}

export function IconDelete() {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.6667 13.0003H21.3333V12.167C21.3333 11.504 21.0699 10.8681 20.6011 10.3992C20.1323 9.93038 19.4964 9.66699 18.8333 9.66699H17.1667C16.5036 9.66699 15.8677 9.93038 15.3989 10.3992C14.9301 10.8681 14.6667 11.504 14.6667 12.167V13.0003H11.3333C11.1123 13.0003 10.9004 13.0881 10.7441 13.2444C10.5878 13.4007 10.5 13.6126 10.5 13.8337C10.5 14.0547 10.5878 14.2666 10.7441 14.4229C10.9004 14.5792 11.1123 14.667 11.3333 14.667H12.1667V23.8337C12.1667 24.4967 12.4301 25.1326 12.8989 25.6014C13.3677 26.0703 14.0036 26.3337 14.6667 26.3337H21.3333C21.9964 26.3337 22.6323 26.0703 23.1011 25.6014C23.5699 25.1326 23.8333 24.4967 23.8333 23.8337V14.667H24.6667C24.8877 14.667 25.0996 14.5792 25.2559 14.4229C25.4122 14.2666 25.5 14.0547 25.5 13.8337C25.5 13.6126 25.4122 13.4007 25.2559 13.2444C25.0996 13.0881 24.8877 13.0003 24.6667 13.0003ZM16.3333 12.167C16.3333 11.946 16.4211 11.734 16.5774 11.5777C16.7337 11.4215 16.9457 11.3337 17.1667 11.3337H18.8333C19.0543 11.3337 19.2663 11.4215 19.4226 11.5777C19.5789 11.734 19.6667 11.946 19.6667 12.167V13.0003H16.3333V12.167ZM22.1667 23.8337C22.1667 24.0547 22.0789 24.2666 21.9226 24.4229C21.7663 24.5792 21.5543 24.667 21.3333 24.667H14.6667C14.4457 24.667 14.2337 24.5792 14.0774 24.4229C13.9211 24.2666 13.8333 24.0547 13.8333 23.8337V14.667H22.1667V23.8337Z" fill="#3E5174"/>
        </svg>
        
    )
}
