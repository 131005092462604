import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import { Container, Col, Row, Card, Button} from 'react-bootstrap';
import axios from 'axios';
import config from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconEdit, IconDelete } from '../../components/icon';

const TP_API_URL = config.TP_API_URL;
let source = axios.CancelToken.source();

const actions = {
    'offer_discount': 'Offer Discount',
    'exclude_this_cart': 'Exclude This Cart'
};

const columns =  memoize(clickHandler => [
    {
        name: 'Name',
        selector: 'name',
        sortable: false,
        right: false,
        cell: row => <div data-tag="allowRowEvents"><div data-tag="allowRowEvents" style={{ fontWeight: 700 }}>{row.name}</div></div>,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: false,
        right: false,
        cell: row => <div data-tag="allowRowEvents">{actions[row.action]}</div>,
    },
    {
        cell: row  => 
            <div style={{  whiteSpace: 'nowrap',  }} className='border1'>
                <button value={row.id} name='edit' style={{ background: 'transparent', border: 'none', padding: 0, cursor: 'pointer' }} onClick={clickHandler}> <IconEdit style={{ fontSize: '1rem' }} /> </button>
                <button value={row.id}  name='delete' style={{ background: 'transparent', border: 'none', padding: 0, cursor: 'pointer' }} onClick={clickHandler}> <IconDelete style={{ fontSize: '1rem' }} /></button>
            </div>,
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
    },
]);

class Segments extends Component {
    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        loading:true,
        loading_text:"Loading...",
        tableloading:false,
        data:[{}],
        segments:[{}],
        total:0,
        page:1
    };

    setPage = (page) => {
        this.setState({page:page});
    }

    getData = () => {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        this.setState({tableloading:true});
        if (source) { source.cancel();}
        source = axios.CancelToken.source();
        axios.get(TP_API_URL+"client/segments", {
            params:{shop_id:tp_shop, page:this.state.page},
            headers:{token: tp_access_token},
            cancelToken: source.token
        }).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                this.setState({
                    loading:false,
                    tableloading:false,
                    data:response.data,
                    segments:response.segments
                });
            } else {
                this.setState({
                    loading_text:response.error_msg
                });
            }
        })
        .catch((e) => {
            this.setState({
                loading_text:e.message
            });
        });
    }

    componentDidMount(){
        let upage = parseInt(this.props.match.params.page);
        if(!upage){ upage=1;}
        this.setState({page:upage});
        this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevState.page!==this.state.page){
            this.getData();
        }
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }

    routeChange=(data)=> {
        this.props.history.push("/dashboard/segments/"+data.id);
    }

    addSegment=()=> {
        this.props.history.push("/dashboard/segments/new");
    }

    handleButtonClick = (e) => {
        const action = e.currentTarget.name;

        if (action === 'edit') {
            this.props.history.push("/dashboard/segments/"+e.currentTarget.value);
        } else if (action === 'delete') {
            if (window.confirm('Are you sure you want to delete this segment?')) {
                const tp_access_token = localStorage.getItem("tp_access_token");
                const tp_shop = localStorage.getItem("tp_shop");
                axios.delete(TP_API_URL+"client/segments/"+e.currentTarget.value, {
                    params:{shop_id:tp_shop},
                    headers:{token: tp_access_token}
                }).then((res) => {
                    const response = res.data;
                    if(response.error_code===401){
                        this.props.history.push("/login");
                    } else if(response.error_code===0){
                        this.getData();
                    } else {
                        alert(response.error_msg);
                    }
                })
                .catch((e) => {
                    alert(e.message);
                });
            }
        }
	};

    render() {
        return(
            this.state.loading ? <div className='loading2'><p>{this.state.loading_text}</p></div> :
            <>
                <Container fluid>
                    <Row>
                        <Col sm={12} className="d-flex justify-content-between align-items-center">
                            <h3 className="page-heading sidebar-btn">
                                Segmentation
                            </h3>
                            <Button variant="success" onClick={this.addSegment} size="sm" className="float-end">
                                <FontAwesomeIcon icon={faPlus} className="menuicon mr-5" /> Add Segment
                            </Button>
                        </Col>
                    </Row>
                </Container>

                <Container fluid>
                    <Row>
                        <Col className='segmentations'>
                            <Card body>
                                <DataTable
                                    noHeader={true}
                                    columns={columns(this.handleButtonClick)}
                                    data={this.state.segments}
                                    highlightOnHover={true}
                                    pointerOnHover={true}
                                    onRowClicked={this.routeChange}
                                    pagination={true}
                                    paginationServer={true}
                                    page={this.state.page}
                                    paginationTotalRows={this.state.data.total_row}
                                    paginationPerPage={50}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true
                                    }}
                                    onChangePage={page => this.setPage(page)}
                                    paginationDefaultPage={this.state.page}
                                    progressPending={this.state.tableloading}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withRouter(Segments);